import React from 'react';
import './PictureFrame.css';

class PictureFrame extends React.Component {
  render() {
    return (
      <>
        <div
          className='Item__Frame1'
          style={
            this.props.ColorBorder
              ? { borderColor: this.props.ColorBorder }
              : { borderColor: 'black' }
          }
        >
          <div className='Item__Frame2'>
            <div className='Item__Frame'>
              <img src={this.props.Picture} alt='personPicture' />
              <span className='Item__FrameShadow'></span>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PictureFrame;

import React from 'react';
import { Translation } from 'react-i18next';
// import SafeURL from '../../../utils/safe-url';
import SubTitles from './Subs/SubTitles';

import { HomeBannerSVG1, HomeBannerSVG2 } from '../../../utils/SVGs/home/Svgs';

import Globe from '../../../assets/home/banner/ClickMe.svg';
import MecetaIcon from '../../../assets/home/banner/maceta.png';
import Mountains from '../../../assets/home/banner/Montana.svg';
import Corchea from '../../../assets/home/banner/Corchea.svg';
import DancingGroot from '../../../assets/dancing-groot/d-g-1.gif';
import DancingGroot2 from '../../../assets/dancing-groot/d-g-2.gif';
import DancingGroot3 from '../../../assets/dancing-groot/d-g-3.gif';
import DancingGrootStop from '../../../assets/dancing-groot/g-s.gif';

import ActionButton from '../../../utils/action-button/ActionButton';
import {
  zpikPink,
  zpikDarkBlue,
  zpikYellow,
} from '../../../utils/color-palatte';

import BannerCollage from '../../../assets/home/banner/CollageBanner.png';

import iWantYouBack from '../../../assets/dancing-groot/i-want-you-back.mp3';
import './HomeBanner.css';
import { joinMsgEn, joinMsgEs } from '../../../config';

let hidden = null;
let visibilityChange = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

class HomeBanner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDancing: false,
      isSoundPlaying: false,
      imagesLoaded: false,
      audioCanPlay: false,
      showFirstDacing: true,
      running: false,
      currentTimeMs: 0,
      currentTimeSec: 0,
      currentTimeMin: 0,
      imgUrls: [DancingGrootStop, DancingGroot, DancingGroot2, DancingGroot3],
      matches: window.matchMedia('(max-width: 650px)').matches,
      bannerLoaded: false,
    };
    this.myRef = React.createRef();
  }

  state = {
    actions: []
  }

  checkImage = (path) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(path);
      img.onerror = () => reject();

      img.src = path;
    });

  audioPromise = () =>
    new Promise((resolve, reject) => {
      let audio = new Audio(iWantYouBack);
      audio.onload = () => resolve();
      audio.onerror = () => reject();

      audio.src = iWantYouBack;
    });

  componentDidMount = () => {
    let audio = new Audio(iWantYouBack);
    audio.addEventListener('canplaythrough', (event) => {
      /* the audio is now playable; play it if permissions allow */
      this.setState({ audioCanPlay: true });
    });

    if (!this.state.imagesLoaded) {
      this.loadImages();
    }

    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia('(max-width: 650px)').addListener(handler);

    document.addEventListener(visibilityChange, this.handleVisibilityChange, false);
  };

  handleVisibilityChange = () => {
    if (document[hidden]) {
      // this.setState({actions: [this.state.actions, 'hide']});
      if (this.state.isDancing) {
        this.toggleDancing()
      }
    } /* else {
     this.setState({actions: [this.state.actions, 'show']});
    } */
  }

  componentWillUnmount()    {
    document.removeEventListener(visibilityChange, this.handleVisibilityChange);
  }

  loadImages = () => {
    let promisesArray = this.state.imgUrls.map(this.checkImage);
    Promise.all(promisesArray).then(
      () => this.setState(() => ({ imagesLoaded: true })),
      () => {
        // setTimeout(() => {
        //   this.loadImages()
        // }, 1000)
        // console.error('could not load images');
      }
    );
  };

  toggleDancing = () => {
    const { isSoundPlaying, isDancing } = this.state;

    let audio = new Audio(iWantYouBack);

    if (!isDancing) {
      setTimeout(() => {
        this.setState({ showFirstDacing: false });
      }, 10000);
    }

    audio.crossOrigin = 'anonymous';
    this.setState({ isDancing: !isDancing, isSoundPlaying: !isSoundPlaying });

    if (isSoundPlaying) {
      this.stop();
      this.myRef.current.pause();
    } else {
      this.start();
      this.myRef.current
        .play()
        .then(() => {
          // Audio is playing.
        })
        .catch(() => {
          this.reset();
          // console.log(" audio.play() error: ", error);
        });
    }
  };

  formatTime = (val, ...rest) => {
    let value = val.toString();
    if (value.length < 2) {
      value = '0' + value;
    }
    if (rest[0] === 'ms' && value.length < 3) {
      value = '0' + value;
    }
    return value;
  };

  start = () => {
    if (!this.state.running) {
      this.setState({ running: true });
      this.watch = setInterval(() => this.pace(), 10);
    }
  };

  stop = () => {
    this.setState({ running: false, showFirstDacing: true });
    clearInterval(this.watch);
  };

  pace = () => {
    const { currentTimeMs, currentTimeSec, currentTimeMin } = this.state;
    this.setState({ currentTimeMs: currentTimeMs + 10 });
    if (currentTimeMs >= 1000) {
      this.setState({ currentTimeSec: currentTimeSec + 1 });
      this.setState({ currentTimeMs: 0 });
    }
    if (currentTimeSec >= 60) {
      this.setState({ currentTimeMin: currentTimeMin + 1 });
      this.setState({ currentTimeSec: 0 });
    }

    if (currentTimeMin >= 2 && currentTimeSec >= 55) {
      this.reset();
    }
  };

  reset = () => {
    this.setState(
      {
        isDancing: false,
        isSoundPlaying: false,
        showFirstDacing: true,
        running: false,
        currentTimeMs: 0,
        currentTimeSec: 0,
        currentTimeMin: 0,
      },
      () => {
        clearInterval(this.watch);
      }
    );
  };

  gif = (src) => (
    <img
      className='Home__banner-gif Home__dancing_groot'
      src={src}
      alt='dancing groot'
      onClick={() => this.toggleDancing()}
    />
  );

  render() {
    const {
      isDancing,
      currentTimeMin,
      currentTimeSec,
      showFirstDacing,
      imagesLoaded,
      audioCanPlay,
      bannerLoaded,
    } = this.state;
    const mediaLoaded = audioCanPlay && imagesLoaded;
    const language = localStorage.getItem('language') === 'us'
      ? 'en'
      : localStorage.getItem('language') || 'es';
    // console.log("state: ", this.state)
    // console.log("imagesLoaded: ", imagesLoaded)
    return (
      <Translation>
        {(t) => (
          <div className='Home__banner'>
            <section className='Home__banner-content'>
              <h1>{t('homeBannerTitle')}</h1>
              <p className='Home__firstParagraph'>{t('HB-firstParagraph')}</p>
              <p className='Home__firstParagraph'>{t('HB-lastParagraph')}</p>
              <div className='Home__ButtonContainer'>
                <ActionButton
                  ButtonClass='link'
                  Padding='20px 30px'
                  Width='unset'
                  Height='75px'
                  BGColor={zpikPink}
                  BGColorHover={zpikYellow}
                  Color='white'
                  FontFamily='Montserrat'
                  FontSize='1.1em'
                  TextAlign='center'
                  TextTransform='uppercase'
                  TextDecoration='none'
                  Margin='30px 10px 0 10px'
                  TextContent={t('HB-button1')}
                  SafeLink='/academy#feature_video'
                />
                <ActionButton
                  ButtonClass='url'
                  Padding='20px 30px'
                  Width='unset'
                  Height='75px'
                  BGColor={zpikDarkBlue}
                  BGColorHover={zpikYellow}
                  Color='white'
                  FontFamily='Montserrat'
                  FontSize='1.8em'
                  TextAlign='center'
                  TextTransform='uppercase'
                  TextDecoration='none'
                  Margin='30px 10px 0 10px'
                  TextContent={t('HB-button2')}
                  SafeUrl={language === 'es' ? joinMsgEs : joinMsgEn}
                  UrlTarget={true}
                />
              </div>
            </section>
            <section
              className='Home__banner-image'
              style={
                bannerLoaded
                  ? mediaLoaded
                    ? {
                        zIndex: '3',
                        marginBottom: `${
                          this.state.matches
                            ? isDancing
                              ? currentTimeMin === 0 && currentTimeSec <= 23
                                ? '0'
                                : '180px'
                              : '0'
                            : '0'
                        }`,
                      }
                    : { zIndex: 'unset' }
                  : { display: 'none' }
              }
            >
              {mediaLoaded ? (
                <>
                  <audio ref={this.myRef} src={iWantYouBack} />
                  {isDancing
                    ? currentTimeMin === 2 &&
                      currentTimeSec >= 3 &&
                      currentTimeSec < 12
                      ? this.gif(DancingGroot)
                      : (currentTimeMin === 1 &&
                          currentTimeSec >= 16 &&
                          currentTimeSec < 25) ||
                        (currentTimeMin === 0 &&
                          currentTimeSec >= 48 &&
                          currentTimeSec < 60) ||
                        (currentTimeMin === 1 &&
                          currentTimeSec >= 0 &&
                          currentTimeSec < 3) ||
                        (currentTimeMin === 1 &&
                          currentTimeSec >= 35 &&
                          currentTimeSec < 50) ||
                        (currentTimeMin === 2 &&
                          currentTimeSec >= 12 &&
                          currentTimeSec < 25)
                      ? this.gif(DancingGroot3)
                      : showFirstDacing
                      ? this.gif(DancingGroot)
                      : this.gif(DancingGroot2)
                    : this.gif(DancingGrootStop)}
                  <img
                    src={MecetaIcon}
                    onClick={() => this.toggleDancing()}
                    className='Home__banner-gif'
                    style={{ width: '200px' }}
                    alt='maceta'
                  />
                  <img
                    src={Globe}
                    alt='globe'
                    className='Home__Banner-ClickMe'
                    onClick={() => this.toggleDancing()}
                    style={isDancing ? { opacity: '0' } : { opacity: '1' }}
                  />
                  <section
                    style={
                      isDancing
                        ? currentTimeMin === 0 && currentTimeSec <= 19
                          ? { display: 'none' }
                          : {
                              display: 'flex',
                              cursor: 'pointer',
                            }
                        : { display: 'none' }
                    }
                    className='Home__Banner-Subtitle-flex'
                    onClick={() => this.toggleDancing()}
                  >
                    <SubTitles
                      currentTimeMin={currentTimeMin}
                      currentTimeSec={currentTimeSec}
                    />
                    <img src={Corchea} alt='Corchea' className='Subs__Note' />
                  </section>
                  <img
                    src={Mountains}
                    className='Home__Banner-Mountains'
                    alt='mountainPath'
                    style={
                      !mediaLoaded
                        ? { display: 'none' }
                        : this.state.matches
                        ? { display: 'block' }
                        : { display: 'none' }
                    }
                  />
                  {/* {isDancing && <Chronometer />} */}
                  {/* <img src={BannerCollage} alt='bannerCollage.png' /> */}
                </>
              ) : (
                <img
                  style={{
                    width: '100%',
                    display: bannerLoaded ? 'block' : 'none',
                  }}
                  src={BannerCollage}
                  alt='bannerCollage.png'
                  className='Home__BannerCollage'
                  onLoad={() => this.setState({ bannerLoaded: true })}
                />
              )}
              <HomeBannerSVG2 />
            </section>
            <img
              src={Mountains}
              className='Home__Banner-Mountains2'
              alt='mountainPath'
              style={
                !mediaLoaded
                  ? { display: 'none' }
                  : this.state.matches
                  ? { display: 'none' }
                  : { display: 'block' }
              }
            />
            <HomeBannerSVG1 />
            
          </div>
        )}
      </Translation>
    );
  }
}

export default HomeBanner;

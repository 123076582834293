import React, { useState } from 'react';
import SafeURL from '../safe-url';
import SafeLINK from '../safe-link';
import { zpikPink } from '../color-palatte';

const ActionButton = ({
  Width,
  Height,
  BGColor,
  Color,
  FontFamily,
  FontSize,
  TextTransform,
  Transform,
  TextDecoration,
  TextAlign,
  Display,
  JustifyContent,
  AlignItems,
  Border,
  Padding,
  Margin,
  Cursor,
  BoxSizing,
  BorderRadius,
  Position,
  ZIndex,
  WidthHover,
  HeightHover,
  BGColorHover,
  ColorHover,
  FontSizeHover,
  FontWeight,
  TransformHover,
  TextDecorationHover,
  TextAlignHover,
  BorderHover,
  PaddingHover,
  MarginHover,
  CursorHover,
  BorderRadiusHover,
  ZIndexHover,
  ButtonClass,
  ClassName,
  Disabled,
  RenderDisabled,
  TextContent,
  ButtonType,
  OnClick,
  UrlType,
  SafeLink,
  SafeUrl,
  UrlTarget,
  style,
  hov,
  unhov,
  OnMouseDown,
  OnMouseUp
}) => {
  const [hover, setHover] = useState(false);
  // eslint-disable-next-line
  const [hovFunct, setHovFunc] = useState(undefined);

  var buttonStyles;

  if (!hover) {
    buttonStyles = {
      width: Width || '150px',
      height: Height || '50px',
      backgroundColor: BGColor || zpikPink,
      color: Color || 'white',
      fontFamily: FontFamily || 'Montserrat',
      fontWeight: FontWeight || '900',
      fontSize: FontSize || '1em',
      textTransform: TextTransform || 'none',
      transform: Transform || 'unset',
      textDecoration: TextDecoration || 'none',
      textAlign: TextAlign || 'left',
      display: Display || 'flex',
      justifyContent: JustifyContent || 'center',
      alignItems: AlignItems || 'center',
      border: Border || 'none',
      padding: Padding || '0px',
      margin: Margin || '0px',
      cursor: Cursor || 'pointer',
      boxSizing: BoxSizing || 'border-box',
      borderRadius: BorderRadius || '8px',
      position: Position || 'static',
      zIndex: ZIndex,
    };
  } else {
    buttonStyles = {
      width: WidthHover || Width || '150px',
      height: HeightHover || Height || '50px',
      backgroundColor: BGColorHover || BGColor || zpikPink,
      color: ColorHover || Color || 'white',
      fontFamily: FontFamily || 'Montserrat',
      fontWeight: FontWeight || '900',
      fontSize: FontSizeHover || FontSize || '1em',
      textTransform: TextTransform || 'none',
      transform: TransformHover || Transform || 'unset',
      textDecoration: TextDecorationHover || TextDecoration || 'none',
      textAlign: TextAlignHover || TextAlign || 'left',
      display: Display || 'flex',
      justifyContent: JustifyContent || 'center',
      alignItems: AlignItems || 'center',
      border: BorderHover || Border || 'none',
      padding: PaddingHover || Padding || '0px',
      margin: MarginHover || Margin || '0px',
      cursor: CursorHover || Cursor || 'pointer',
      boxSizing: BoxSizing || 'border-box',
      borderRadius: BorderRadiusHover || BorderRadius || '8px',
      zIndexHover: ZIndexHover || ZIndex,
    };
  }

  const SetHover = () => {
    setHover(true);
    setHovFunc(hov)
  }

  const UnHover = () => {
    setHover(false);
    setHovFunc(unhov)
  }

  return (
    <>
      {ButtonClass === 'button' || ButtonClass === 'Button' ? (
        <button
          type={ButtonType}
          className={ClassName}
          onClick={OnClick}
          style={buttonStyles}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
          disabled={Disabled}
          onMouseDown={OnMouseDown}
          onMouseUp={OnMouseUp}
        >
          {/**
           * The disabled prop is only used for buttons
           *
           * the Render disabled is a element prop passed when the
           * button is showing an load
           *
           */}
          {Disabled ? RenderDisabled : TextContent}
        </button>
      ) : undefined}
      {ButtonClass === 'Link' || ButtonClass === 'link' ? (
        <SafeLINK
          className={ClassName}
          style={buttonStyles}
          onClick={OnClick}
          onMouseEnter={() => SetHover()}
          onMouseLeave={() => UnHover()}
          onMouseDown={OnMouseDown}
          onMouseUp={OnMouseUp}
          text={TextContent}
          dangerousLINK={SafeLink}
        />
      ) : undefined}
      {ButtonClass === 'Url' || ButtonClass === 'url' ? (
        <SafeURL
          className={ClassName}
          style={buttonStyles}
          onClick={OnClick}
          onMouseEnter={() => SetHover()}
          onMouseLeave={() => UnHover()}
          onMouseDown={OnMouseDown}
          onMouseUp={OnMouseUp}
          text={TextContent}
          dangerousURL={UrlType ? `${UrlType}:${SafeUrl}` : SafeUrl}
          newTab={UrlTarget}
        />
      ) : undefined}
    </>
  );
};

export default ActionButton;

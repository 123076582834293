import React from 'react';
import './SubTitles.css';

const SubTitles = ({
  currentTimeMin,
  currentTimeSec,
}) => {
  return (
    <div className='Chrometer__container'>
      {currentTimeMin === 0 && currentTimeSec >= 14 && currentTimeSec < 19 && (
        <h3 className='Chrometer__lyric'>Uh-huh huh huh huh</h3>
      )}
      {currentTimeMin === 0 && currentTimeSec >= 19 && currentTimeSec < 23 && (
        <h3 className='Chrometer__lyric'>Uh-huh huh huh huh</h3>
      )}
      {currentTimeMin === 0 && currentTimeSec >= 23 && currentTimeSec < 29 && (
        <h3 className='Chrometer__lyric'>Let me tell ya now, Uh-huh</h3>
      )}
      {currentTimeMin === 0 && currentTimeSec >= 29 && currentTimeSec < 31 && (
        <h3 className='Chrometer__lyric'>When I had you to myself</h3>
      )}
      {currentTimeMin === 0 && currentTimeSec >= 31 && currentTimeSec < 34 && (
        <h3 className='Chrometer__lyric'>I didn't want you around</h3>
      )}
      {currentTimeMin === 0 && currentTimeSec >= 34 && currentTimeSec < 38 && (
        <h3 className='Chrometer__lyric'> Those pretty faces always made you stand out in a crowd </h3>
      )}
      {currentTimeMin === 0 && currentTimeSec >= 38 && currentTimeSec < 41 && (
        <h3 className='Chrometer__lyric'> But someone picked you from the bunch </h3>
      )}
      {currentTimeMin === 0 && currentTimeSec >= 41 && currentTimeSec < 44 && (
        <h3 className='Chrometer__lyric'>When glance was all it took</h3>
      )}
      {currentTimeMin === 0 && currentTimeSec >= 44 && currentTimeSec < 48 && (
        <h3 className='Chrometer__lyric'>Now it's much too late for me to take a second look</h3>
      )}
      {currentTimeMin === 0 && currentTimeSec >= 48 && currentTimeSec < 53 && (
        <h3 className='Chrometer__lyric'>Oh, baby, give me one more chance, (Show you that I love you)</h3>
      )}
      {currentTimeMin === 0 && currentTimeSec >= 53 && currentTimeSec < 60 && (
        <h3 className='Chrometer__lyric'>Won't you please let me, (back to your heart)</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 0 && currentTimeSec < 3 && (
        <h3 className='Chrometer__lyric'>Oh, darlin', I was blind to let you go, (Let you go, baby)</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 3 && currentTimeSec < 7 && (
        <h3 className='Chrometer__lyric'>But now since I see you in his arms, (I want you back)</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 7 && currentTimeSec < 10 && (
        <h3 className='Chrometer__lyric'>Yes, I do now, (I want you back)</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 10 && currentTimeSec < 12 && (
        <h3 className='Chrometer__lyric'>Ooh, ooh, baby, (I want you back)</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 12 && currentTimeSec < 15 && (
        <h3 className='Chrometer__lyric'>Ya, ya, ya, ya (I want you back)</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 15 && currentTimeSec < 16 && (
        <h3 className='Chrometer__lyric'>Na, na, na, na</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 16 && currentTimeSec < 21 && (
        <h3 className='Chrometer__lyric'>Tryin' to live without your love is one long sleepless night</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 21 && currentTimeSec < 25 && (
        <h3 className='Chrometer__lyric'>Let me show you girl, That I know wrong from right</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 25 && currentTimeSec < 30 && (
        <h3 className='Chrometer__lyric'>Every street you walk on, I leave tearstains on the ground</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 30 && currentTimeSec < 34 && (
        <h3 className='Chrometer__lyric'>Following the girl, I didn't even want around</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 34 && currentTimeSec < 35 && (
        <h3 className='Chrometer__lyric'>Let me tell you now</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 35 && currentTimeSec < 40 && (
        <h3 className='Chrometer__lyric'>Oh, baby, all I need is one more chance, (Show you that I love you)</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 40 && currentTimeSec < 45 && (
        <h3 className='Chrometer__lyric'>Won't you please let me, (Back to your heart)</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 45 && currentTimeSec < 50 && (
        <h3 className='Chrometer__lyric'>Oh, darlin', I was blind to let you go, (Let you go baby)</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 50 && currentTimeSec < 56 && (
        <h3 className='Chrometer__lyric'>But now since I see you in his arms</h3>
      )}
      {currentTimeMin === 1 && currentTimeSec >= 56 && currentTimeSec < 58 && (
        <h3 className='Chrometer__lyric'>Uh-huh</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 0 && currentTimeSec < 2 && (
        <h3 className='Chrometer__lyric'>A buh, buh, buh, buh</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 2 && currentTimeSec < 4 && (
        <h3 className='Chrometer__lyric'>A buh, buh, buh, buh</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 2 && currentTimeSec < 4 && (
        <h3 className='Chrometer__lyric'>A buh, buh, buh, buh</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 4 && currentTimeSec < 6 && (
        <h3 className='Chrometer__lyric'>A buh, buh, buh, buh, All I want</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 6 && currentTimeSec < 8 && (
        <h3 className='Chrometer__lyric'>A buh, buh, buh, buh, All I need</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 8 && currentTimeSec < 10 && (
        <h3 className='Chrometer__lyric'>A buh, buh, buh, buh, All I want</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 10 && currentTimeSec < 12 && (
        <h3 className='Chrometer__lyric'>A buh, buh, buh, buh, All I need</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 12 && currentTimeSec < 17 && (
        <h3 className='Chrometer__lyric'>Oh, just one more chance, To show you that I love you</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 17 && currentTimeSec < 21 && (
        <h3 className='Chrometer__lyric'>Baby, baby, baby, Baby, baby, baby, (I want you back)</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 21 && currentTimeSec < 25 && (
        <h3 className='Chrometer__lyric'>Forget what happened then, (I want you back), Let me live again</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 25 && currentTimeSec < 30 && (
        <h3 className='Chrometer__lyric'>Oh, baby, I was blind to let you go</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 30 && currentTimeSec < 34 && (
        <h3 className='Chrometer__lyric'>But now since I see you in his arms, (I want you back)</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 34 && currentTimeSec < 38 && (
        <h3 className='Chrometer__lyric'>Spare me of this cause, Gimme back what I lost</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 39 && currentTimeSec < 44 && (
        <h3 className='Chrometer__lyric'>Oh, baby, I need one more chance, ha, I tell ya that I love you</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 44 && currentTimeSec < 48 && (
        <h3 className='Chrometer__lyric'>Baby, oh!, Baby, oh!, Baby, oh!</h3>
      )}
      {currentTimeMin === 2 && currentTimeSec >= 48 && currentTimeSec < 51 && (
        <h3 className='Chrometer__lyric'>I want you back!, I want you back!</h3>
      )}
    </div>
  )
}

export default SubTitles;
import React from 'react';
import { Translation } from 'react-i18next';
import {
  AboutContainerSVG,
  AboutContainerSVG2,
  AboutContainerSVG2a,
} from '../../../utils/SVGs/academy/Svgs';
import Collage from '../../../assets/home/banner/CollageBanner.png';

import './AcademyAbout.css';

function AcademyAbout() {
  return (
    <Translation>
      {(t) => (
        <div className='About__Container'>
          <div className='About__Content'>
            <h1 className='About__h1'>
              {t('about')}
              {/* <br />
              zpik online */}
            </h1>
            <p>
              {t('aboutFirstText')}
            </p>
            <p>
              {t('aboutSecondText')}
            </p>
            <p>
              {t('aboutThirdText')}
            </p>
          </div>
          <img src={Collage} alt='collage' className='About__Collage' />
          <AboutContainerSVG />
          <AboutContainerSVG2 />
          <AboutContainerSVG2a />
        </div>
      )}
    </Translation>
  );
}

export default AcademyAbout;

import React from 'react';
import PictureFrame from '../../../../utils/picture-frame/PictureFrame'
import './CarouselItem.css';

class CarouselItem extends React.Component {
  render() {
    const { personName, personPicture, borderColor, personOpinion } = this.props;
    return (
      <div className='Carousel__Item-container'>
        <h1 className='Carousel__personName'>{personName}</h1>
        <div className='Item__Opinion'>
          <div className='Item__Frame-container'>
            <PictureFrame
              ColorBorder={borderColor}
              Picture={personPicture}
            />
          </div>
          <div className="Item__OpinionText-Container">
            <p className="Item__Opinion-paragraph">{personOpinion}</p>
          </div>
        </div>
      </div>
    );
  }
}

export default CarouselItem;

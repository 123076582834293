import React from 'react';
import AcademyAbout from '../../components/academy/about/AcademyAbout';
import Methodology from '../../components/academy/methodology/Methodology';
import AcademyTeachers from '../../components/academy/teachers/AcademyTeachers';
import './Academy.css';

const Academy = () => (
  <div className='academy'>
    <AcademyAbout />
    <Methodology />
    <AcademyTeachers />
  </div>
);

export default Academy;

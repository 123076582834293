import React, { useEffect, useState } from 'react';
import Asgard from '../../../assets/levels/BackgroundAsgard.jpg';
import {
  JoinBannerSVGTop,
  JoinBannerSVGBottom,
} from '../../../utils/SVGs/levels/Svgs';
import {
  zpikDarkBlue,
  zpikPink,
  zpikYellow,
} from '../../../utils/color-palatte';
import Loki from '../../../assets/levels/Loki.png';
import JoinEn from '../../../assets/levels/JoinEN.svg';
import JoinEs from '../../../assets/levels/JoinES.svg';
import Tesseract from '../../../assets/levels/Tesseract_Background.jpeg';
import ActionButton from '../../../utils/action-button/ActionButton';
import { levelingMsgEn, levelingMsgEs } from '../../../config';
import { Translation } from 'react-i18next';
import './JoinBanner.css';

const JoinBanner = () => {
  const language =
    localStorage.getItem('language') === 'us'
      ? 'en'
      : localStorage.getItem('language') || 'es';

  const [firstMatches, setFirstMatches] = useState(
    window.matchMedia('(max-width: 900px)').matches
  );
  const [secondMatches, setSecondMatches] = useState(
    window.matchMedia('(max-width: 650px)').matches
  );

  useEffect(() => {
    const firstHandler = (e) => setFirstMatches(e.matches);
    window.matchMedia('(max-width: 900px)').addListener(firstHandler);
    const secondHandler = (e) => setSecondMatches(e.matches);
    window.matchMedia('(max-width: 650px)').addListener(secondHandler);
  }, []);

  var date = new Date();

  var hour = date.getHours();

  var filter;

  if (hour > 19 && hour < 23) {
    filter = '0,20,40,.7';
  }
  if (hour === 23) {
    filter = '0,20,40,.7';
  }
  if (hour >= 0 && hour <= 5) {
    filter = '0,20,40,.7';
  }
  if (hour >= 6 && hour <= 10) {
    filter = '210,190,140,.5';
  }
  if (hour >= 11 && hour <= 15) {
    filter = '200,200,200,.5';
  }
  if (hour >= 16 && hour <= 19) {
    filter = '220,160,130,.6';
  }

  return (
    <Translation>
      {(t) => (
        <div
          className='JoinBanner__Container'
          style={{ backgroundImage: `url(${Asgard})` }}
        >
          <JoinBannerSVGTop />
          <div
            className='JoinBanner__Filter'
            style={{ background: `rgba(${filter})` }}
          >
            <img className='LOKI' src={Loki} alt='loki' />
            <img
              className='lokiGlobe'
              src={language === 'es' ? JoinEs : JoinEn}
              alt='globe'
            />
            {!secondMatches && (
              <div className='JoinBanner__Button-Container'>
                <ActionButton
                  ButtonClass='link'
                  Width={
                    firstMatches
                      ? /* secondMatches ? '100px' : */ '180px'
                      : '250px'
                  }
                  Height='60px'
                  Padding='5px'
                  TextAlign='center'
                  TextTransform='uppercase'
                  FontSize={
                    firstMatches
                      ? /* secondMatches ? '.7em' : */ '.9em'
                      : '1.2em'
                  }
                  TextContent={t('JoinButton1')}
                  BGColor={zpikDarkBlue}
                  BGColorHover={zpikYellow}
                  ClassName='JoinBanner__Button1'
                  SafeLink='/academy'
                />
                <ActionButton
                  ButtonClass='url'
                  Width={
                    firstMatches
                      ? /* secondMatches ? '100px' : */ '180px'
                      : '250px'
                  }
                  Height='60px'
                  Padding='5px'
                  TextAlign='center'
                  TextTransform='uppercase'
                  FontSize={
                    firstMatches
                      ? /* secondMatches ? '.7em' : */ '.9em'
                      : '1.2em'
                  }
                  TextContent={t('JoinButton2')}
                  BGColor={zpikPink}
                  BGColorHover={zpikYellow}
                  ClassName='JoinBanner__Button2'
                  SafeUrl={language === 'es' ? levelingMsgEs : levelingMsgEn}
                />
              </div>
            )}
            {secondMatches && (
              <div className='JoinBanner__Tesseract'>
                <div
                  className='JoinBanner__ImageTesseract'
                  style={{ backgroundImage: `url(${Tesseract})` }}
                >
                  <div className='JoinBanner__Button-Container'>
                    <ActionButton
                      ButtonClass='link'
                      Width='250px'
                      Height='60px'
                      Padding='5px'
                      TextAlign='center'
                      TextTransform='uppercase'
                      FontSize='1.2em'
                      TextContent={t('JoinButton1')}
                      BGColor={zpikDarkBlue}
                      BGColorHover={zpikYellow}
                      ClassName='JoinBanner__Button1'
                      SafeLink='/academy'
                    />
                    <ActionButton
                      ButtonClass='url'
                      Width='250px'
                      Height='60px'
                      Padding='5px'
                      TextAlign='center'
                      TextTransform='uppercase'
                      FontSize='1.2em'
                      TextContent={t('JoinButton2')}
                      BGColor={zpikPink}
                      BGColorHover={zpikYellow}
                      ClassName='JoinBanner__Button2'
                      SafeUrl={
                        language === 'es' ? levelingMsgEs : levelingMsgEn
                      }
                      UrlTarget={true}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <JoinBannerSVGBottom />
        </div>
      )}
    </Translation>
  );
};

export default JoinBanner;

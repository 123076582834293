import React from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import { Translation } from 'react-i18next';
import CarouselItem from './item/CarouselItem';
// import PictureGuy from '../../../assets/home/carousel/PictureGuy.png';
import nelio from '../../../assets/home/carousel/nelio.png';
import { zpikPink } from '../../../utils/color-palatte'
import { HomeCarouselSVG1, HomeCarouselSVG2 } from '../../../utils/SVGs/home/Svgs'
import './HomeCarousel.css';

const HomeCarousel = () => {
  const items = [
    {
      personName: 'Nelio Finol',
      personPicture: nelio,
      borderColor: zpikPink,
      personOpinion: <Translation>{t => t('nelio')}</Translation>
    },
    // {
    //   personName: 'Nelio Finol',
    //   personPicture: nelio,
    //   borderColor: zpikPink,
    //   personOpinion:<Translation>{t => t('nelio')}</Translation>
    // },
    // {
    //   personName: 'ronald burgos',
    //   personPicture: PictureGuy,
    //   borderColor: zpikPink,
    //   personOpinion:
    //     'Lorem ipsum dolor sit amet consectetur, adipisicing elit. Voluptatibus, tempore nostrum! Eligendi aut placeat nesciunt. Odio, vel. Ipsam itaque, saepe commodi id, quia quo, tempora assumenda quos consequuntur velit qui!',
    // },
  ];
  const myArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? '❰' : '❱';
    return (
      <button
        className='Home__Carousel-btn'
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  };

  return (
    <Translation>
      {(t) => (
        <div className='Home__Carousel'>
          <HomeCarouselSVG1 />
          <h1 className='Home__Carousel-h1'>{t('realExperiences')}</h1>
          <Carousel
            renderArrow={myArrow}
            renderPagination={({ pages, activePage, onClick }) => {
              return (
                <div className='Home__Carousel-Flex'>
                  {pages.map((page) => {
                    const isActivePage = activePage === page;
                    return (
                      <div
                        className='Home__Carousel-dot'
                        key={page}
                        onClick={() => onClick(page)}
                        active={isActivePage}
                        style={isActivePage ? {borderColor: zpikPink }:{borderColor: 'transparent'}}
                      >
                        <span
                          className="Home__Carousel-Inactive"
                          style={isActivePage ? {display: 'none' }:{display: 'block'}}
                        >
                        </span>
                        <span
                          className="Home__Carousel-Active"
                          style={isActivePage ? {display: 'block' }:{display: 'none'}}
                        >
                        </span>
                      </div>
                    );
                  })}
                </div>
              );
            }}
          >
            {items.map((item) => (
              <CarouselItem {...item} />
            ))}
          </Carousel>
          <HomeCarouselSVG2 />
        </div>
      )}
    </Translation>
  );
};

export default HomeCarousel;

import React from 'react'
import './loaders.css'

export const Loader1 = ({dotColor}) => (
  <div className="loader1">
    <div className="dot1" style={{backgroundColor: dotColor || 'white'}}/>
    <div className="dot1" style={{backgroundColor: dotColor || 'white'}}/>
    <div className="dot1" style={{backgroundColor: dotColor || 'white'}}/>
    <div className="dot1" style={{backgroundColor: dotColor || 'white'}}/>
    <div className="dot1" style={{backgroundColor: dotColor || 'white'}}/>
    <div className="dot1" style={{backgroundColor: dotColor || 'white'}}/>
    <div className="dot1" style={{backgroundColor: dotColor || 'white'}}/>
    <div className="dot1" style={{backgroundColor: dotColor || 'white'}}/>
  </div>
)
import React, { useState } from 'react';
import SafeLINK from '../../utils/safe-link';
import { Translation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import ReactFlagsSelect from 'react-flags-select';
import HeaderLogo from '../../assets/header/LOGO.svg';
import { zpikYellow } from '../../utils/color-palatte';
import BtnMenu from '../../assets/header/btn-menu.svg';

import './Header.css';

const Header = () => {
  const location = useLocation();
  const [selected, setSelected] = useState(
    String(
      localStorage.getItem('language') ||
        (navigator.language === 'en-US' ? 'US' : 'ES')
    ).toUpperCase()
  );

  const toggleMenu = () => {
    var men = document.getElementById('header__btn-menu');
    men.checked = false;
  };

  return (
    <Translation>
      {(t) => (
        <div className='header'>
          <section className='header__menu'>
            <SafeLINK
              className='header__SecondLogo'
              dangerousLINK='/'
              text={<img src={HeaderLogo} alt='LOGO.svg' />}
            />
            <input type='checkbox' id='header__btn-menu' />
            <label htmlFor='header__btn-menu'>
              <p>menu</p>
              <img src={BtnMenu} alt='' />
            </label>
            <ul className='header__ul'>
              <li className='header__ul-li'>
                <SafeLINK
                  dangerousLINK='/home'
                  className='header__li'
                  text={t('home')}
                  style={
                    location.pathname === '/' || location.pathname === '/home'
                      ? {
                          borderBottomColor: zpikYellow,
                          backgroundColor: 'transparent',
                        }
                      : {
                          borderBottomColor: 'transparent',
                          backgroundColor: 'white',
                        }
                  }
                  onClick={() => toggleMenu()}
                />
              </li>
              <li className='header__ul-li'>
                <SafeLINK
                  dangerousLINK='/academy'
                  className='header__li'
                  text={t('academy')}
                  style={
                    location.pathname === '/academy'
                      ? {
                          borderBottomColor: zpikYellow,
                          backgroundColor: 'transparent',
                        }
                      : {
                          borderBottomColor: 'transparent',
                          backgroundColor: 'white',
                        }
                  }
                  onClick={() => toggleMenu()}
                />
              </li>
              <li className='header__ul-li'>
                <SafeLINK
                  dangerousLINK='/'
                  className='header__img'
                  text={<img src={HeaderLogo} alt='LOGO.svg' />}
                />
              </li>
              <li className='header__ul-li'>
                <SafeLINK
                  dangerousLINK='/levels'
                  className='header__li'
                  text={t('levels')}
                  style={
                    location.pathname === '/levels'
                      ? {
                          borderBottomColor: zpikYellow,
                          backgroundColor: 'transparent',
                        }
                      : {
                          borderBottomColor: 'transparent',
                          backgroundColor: 'white',
                        }
                  }
                  onClick={() => toggleMenu()}
                />
              </li>
              <li className='header__ul-li'>
                <SafeLINK
                  dangerousLINK='/contact'
                  className='header__li'
                  text={t('contact')}
                  style={
                    location.pathname === '/contact'
                      ? {
                          borderBottomColor: zpikYellow,
                          backgroundColor: 'transparent',
                        }
                      : {
                          borderBottomColor: 'transparent',
                          backgroundColor: 'white',
                        }
                  }
                  onClick={() => toggleMenu()}
                />
              </li>
            </ul>
          </section>
          <div className='flags-select-container'>
            <ReactFlagsSelect
              // ref={this.userFlag}
              countries={['US', 'ES']}
              showSelectedLabel={false}
              showOptionLabel={false}
              fullWidth={false}
              placeholder=' '
              selectedSize={24}
              optionsSize={16}
              selected={selected}
              onSelect={(selected) => {
                setSelected(
                  selected,
                  localStorage.setItem(
                    'language',
                    String(selected).toLowerCase()
                  ),
                  window.location.reload(false)
                );
              }}
              className='header__flagSelect'
            />
          </div>
        </div>
      )}
    </Translation>
  );
};

export default Header;

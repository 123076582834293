import React from 'react';
import './Svgs.css';

// HomeBanner

export const HomeBannerSVG1 = () => (
  <svg
    className='Home__BannerSVG'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 -1 A 60 78 -20 0 0 100 80 L 101 101 L -1 101z' />
  </svg>
);

export const HomeBannerSVG2 = () => (
  <svg
    className='Home__BannerSVG2'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 -1 A 60 170 0 0 0 101 -1 L 101 101 L -1 101z' />
  </svg>
);

// Home Levels

export const HomeLevelsSVG1 = () => (
  <svg
    className='Home__Levels-SVG1'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 101 A 40 75 0 0 1 101 101 L 101 -1 L -1 -1z' />
  </svg>
);

export const HomeLevelsSVG2 = () => (
  <svg
    className='Home__Levels-SVG2'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 -1 A 40 75 0 1 0 101 -1 L 101 101 L -1 101z' />
  </svg>
);

// Home Video Banner

export const HomeVideoSVG1 = () => (
  <svg
    className='Home__Video-SVG1'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 101 A 80 310 0 0 1 101 60 L 101 -1 L -1 -1z' />
  </svg>
);

export const HomeVideoSVG2 = () => (
  <svg
    className='Home__Video-SVG2'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 -1 A 30 55 0 1 0 101 -1 L 101 101 L -1 101z' />
  </svg>
);

// Home Carousel

export const HomeCarouselSVG1 = () => (
  <svg
    className='Home__Carousel-SVG1'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 40 A 61 120 0 0 1 101 101 L 101 -1 L -1 -1z' />
  </svg>
);

export const HomeCarouselSVG2 = () => (
  <svg
    className='Home__Carousel-SVG2'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 -1 A 40 75 0 1 0 101 -1 L 101 101 L -1 101z' />
  </svg>
);

import React from 'react';
import { Translation } from 'react-i18next';
import ActionButton from '../../../../utils/action-button/ActionButton';
import { zpikDarkBlue } from '../../../../utils/color-palatte';
import './LevelCards.css';

class LevelCards extends React.Component {
  render() {
    const { number, image, title, paragraph, to } = this.props;
    return (
      <Translation>
        {(t) => (
          <div
            className='Cards__container'
            style={
              number === '' || number === undefined
                ? { display: 'none' }
                : { display: 'flex' }
            }
          >
            <div className='Cards__firsDiv'>
              <h1>#{number}</h1>
              <div className='Cards__img-container'>
                <img src={image} alt='card-img' />
              </div>
            </div>
            <h2 className='Cards__title'>{title}</h2>
            <div className='Cards__Paragraph'>
              {paragraph}
            </div>
            <ActionButton
              ButtonClass='Link'
              Width='unset'
              Height='unset'
              SafeLink={to}
              TextContent={t('moreInfo')}
              Padding='10px 20px'
              Color='white'
              Transform='scale(1.1, 0.9)'
              FontWeight='900'
              FontSize='1.3em'
              BGColor={zpikDarkBlue}
              BorderRadius='10px'
              TextTransform='uppercase'
            />
            {/* <SafeLINK className='Cards__button' text={t('moreInfo')} dangerousLINK={to} /> */}
          </div>
        )}
      </Translation>
    );
  }
}

export default LevelCards;

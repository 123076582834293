import React from 'react';
import PictureFrame from '../../../../utils/picture-frame/PictureFrame';
import './TeacherCards.css';

const TeacherCards = ({
  TeacherPicture,
  BorderColor,
  TeacherName,
  TeacherProfession,
}) => {
  return (
    <div className='TeacherCards__Container'>
      <PictureFrame Picture={TeacherPicture} ColorBorder={BorderColor} />
      <h1 className='Teacher__Name'>{TeacherName || 'Name'}</h1>
      {/* <p className='Teacher__Profession'>{TeacherProfession || 'Profession'}</p> */}
    </div>
  );
};

export default TeacherCards;

import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom';
import Layout from '../components/Layout';
import Academy from '../containers/academy/Academy';
import Contact from '../containers/contact/Contact';
import Home from '../containers/home/Home';
import Levels from '../containers/levels/Levels';
import ScrollToTop from '../utils/ScrollToTop';

const App = () => (
  <Router>
    <ScrollToTop />
    <Layout>
      <Switch>
        <Route path='/home'>
          <Home />
        </Route>
        <Route path='/academy'>
          <Academy />
        </Route>
        <Route path='/levels'>
          <Levels />
        </Route>
        <Route path='/contact'>
          <Contact />
        </Route>
        <Route path='/'>
          <Home />
        </Route>
      </Switch>
    </Layout>
  </Router>
);

export default App;

import React from 'react';
import Input from '../../../utils/Input';
import TextArea from '../../../utils/TextArea';
// import SendButton from '../../../utils/send-button/SendButton';
import { postData } from '../../../fetch/index';
import { Translation } from 'react-i18next';
import HDYG from '../../../assets/contact/suggestions/HDYG.svg';
import HDYGESP from '../../../assets/contact/suggestions/HDYGESP.svg';
import Rocket from '../../../assets/contact/suggestions/Rocket.png';
import {
  SuggestionsSvgTop,
  SuggestionsSvgBottom,
} from '../../../utils/SVGs/contact/Svgs';
import Alert from '../../general/contact-us/alert/Alert';
import './Suggestions.css';
import ActionButton from '../../../utils/action-button/ActionButton';
import { Loader1 } from '../../../utils/loaders/loaders';

class Suggestions extends React.Component {
  state = {
    suggestionLoading: false,
    suggestionMessage: '',
    suggestionIssue: '',
  };

  onContact = (event, t) => {
    event.preventDefault();
    if (this.state.suggestionIssue && this.state.suggestionMessage) {
      this.setState({ suggestionLoading: true }, async () => {
        try {
          const data = {
            name: this.state.suggestionIssue,
            email: 'Suggestion',
            message: this.state.suggestionMessage,
          };
          await postData('/contact/create-contact/', data, false);
          // console.log("onContact / response: ", response);
          this.showAlert(true, t('suggestionSended'), true);
          this.setState({
            suggestionLoading: false,
            suggestionIssue: '',
            suggestionMessage: '',
            showInputsErrorContact: false,
          });
        } catch (e) {
          // console.log("onContact / error: ", e);
          const error =
            e && e.data && e.data.error && e.data.error.message
              ? e.data.error.message === "internal-error"
                ? t('internalError')
                : e.data.error.message
              : t('internalError');
          this.showAlert(true, error, false);
          this.setState({ suggestionLoading: false });
        }
      });
    } else {
      this.setState({ showInputsErrorContact: true });
      if (this.state.suggestionIssue && !this.state.suggestionIssue) {
        this.showAlert(true, t('emailIsInvalid'), false);
      }
    }
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  showAlert = (isOpen, content, success) =>
  this.setState({
    alertSuccess: success,
    alertContent: content,
    isAlertOpen: isOpen,
  });

  render() {
    const language =
      localStorage.getItem('language') === 'us'
        ? 'en'
        : localStorage.getItem('language') || 'es';
    return (
      <Translation>
        {(t) => (
          <div className='Suggestions__Container'>
            <SuggestionsSvgTop />
            <h1>{t('suggestions')}</h1>
            <div className='Suggestions__Form-Container'>
              <form
                className='Suggestion__Form'
                noValidate
                autoComplete='off'
                onSubmit={(e) => this.onContact(e, t)}
              >
                <Input
                  placeholder={t('issue')}
                  type='text'
                  width='100%'
                  value={this.state.suggestionIssue}
                  onChange={this.handleInputChange}
                  name='suggestionIssue'
                  disabled={this.state.suggestionLoading}
                />
                <TextArea
                  rows='4'
                  cols='15'
                  placeholder={t('PHolder3')}
                  name='suggestionMessage'
                  showErrors={this.state.showInputsErrorContact}
                  className='MsgBox'
                  onChange={this.handleInputChange}
                  value={this.state.suggestionMessage}
                  disabled={this.state.suggestionLoading}
                  isRequired
                />
                {/* <SendButton
                  type='submit'
                  disabled={this.state.suggestionLoading}
                /> */}
                <ActionButton
                    ButtonClass='button'
                    ButtonType='submit'
                    Padding='10px'
                    Width='180px'
                    Height='50px'
                    FontWeight='700'
                    TextTransform='uppercase'
                    BorderRadius='8px'
                    FontSize='1.9em'
                    TextContent={t('send')}
                    Position='relative'
                    RenderDisabled={<Loader1 />}
                    Disabled={this.state.suggestionLoading}
                  />
              </form>
              <div className='Suggestions__Imgs-container'>
                <img src={Rocket} alt='Groot' className='Suggestions__image' />
                <img
                  src={language === 'es' ? HDYGESP : HDYG}
                  alt='globe'
                  className='Suggestions__img-globe'
                />
              </div>
            </div>
            <SuggestionsSvgBottom />
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
          </div>
        )}
      </Translation>
    );
  }
}

export default Suggestions;

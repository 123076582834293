import React, { useState, useEffect } from 'react';
import HomeBanner from '../../components/home/banner/HomeBanner';
import HomeLevels from '../../components/home/levels/HomeLevels';
import HomeVideoBanner from '../../components/home/video-banner/HomeVideoBanner';
import HomeCarousel from '../../components/home/carousel/HomeCarousel';
import ContactUs from '../../components/general/contact-us/ContactUs'
import './Home.css';
import { Translation } from 'react-i18next';
import Leveling from '../../components/general/leveling/Leveling';
import BG1 from '../../assets/general/leveling/BG1.jpg'
import { zpikWhiteBackground } from '../../utils/color-palatte';

function Home() {

  const [firstMatches, setFirstMatches] = useState(
    window.matchMedia('(max-width: 900px)').matches
  );
  const [secondMatches, setSecondMatches] = useState(
    window.matchMedia('(max-width: 650px)').matches
  );

  useEffect(() => {
    const firstHandler = (e) => setFirstMatches(e.matches);
    window.matchMedia('(max-width: 900px)').addListener(firstHandler);
    const secondHandler = (e) => setSecondMatches(e.matches);
    window.matchMedia('(max-width: 650px)').addListener(secondHandler);
  }, [])

  return (
    <div className='home'>
      <HomeBanner />
      <HomeLevels />
      <HomeVideoBanner />
      <Leveling
        SVGTop
        ImgBG={BG1}
        Title={<Translation>{t => t('levelingTest')}</Translation>}
        titleFontSize={firstMatches ? secondMatches ? '2.3em' : '2.5em' : '3.2em'}
        titleFontWeight='900'
        titleTextTransform='uppercase'
        titlePaddingTop={firstMatches ? secondMatches ? '160px' : '150px' : '140px'}
        Paragraph={<Translation>{t => t('levelingFirstParagraph')}</Translation>}
        paragraphSize={firstMatches ? '.8em' : '1em'}
        // paragraphTransform='uppercase'
        subHeading={<Translation>{t => t('levelingSubheading')}</Translation>}
        svgFill={zpikWhiteBackground}
      />
      <HomeCarousel />
      <ContactUs
        title={<Translation>{t => t('becomeAZpiker')}</Translation>}
        classColor='pink'
        alignButton='center'
        RenderSVGTop={true}
        RenderSVGBottom={true}
      />
    </div>
  );
}

export default Home;

import React from 'react';
import { Translation } from 'react-i18next';
import LevelMap from '../../components/levels/levelmap/LevelMap';
import Fire from '../../assets/home/levels/Fire.png';
import Portal from '../../assets/home/levels/PistolPortal.png';
import Guntlet from '../../assets/home/levels/Infinite.png';
import Methf from '../../assets/home/levels/99percent.png';
import Bazinga from '../../assets/home/levels/Bazinga.png';
import Queen from '../../assets/levels/QueenGlow.png';
import Rick from '../../assets/levels/RickGlow.png';
import Thanos from '../../assets/levels/ThanosGlow.png';
import Break from '../../assets/levels/BreakGlow.png';
import Sheldon from '../../assets/levels/SheldonGlow.png';
import {
  Level1SvgBottom,
  Level2SvgTop,
  Level2SvgBottom,
  Level3SvgTop,
  Level3SvgBottom,
  Level4SvgTop,
  Level4SvgBottom,
  Level5SvgTop,
  Level5SvgBottom,
} from '../../utils/SVGs/levels/Svgs';
import './Levels.css';
import JoinBanner from '../../components/levels/join-banner/JoinBanner';
import Leveling from '../../components/general/leveling/Leveling';
import BG2 from '../../assets/general/leveling/BG2.jpg'

function Levels() {
  const level = [
    {
      FirstTitle: 'all levels',
      LevelNumber: '1',
      LevelTitle: <Translation>{(t) => t('warmingUp')}</Translation>,
      LevelImage: Fire,
      Paragraph1: <Translation>{(t) => t('level1FirstParagraph')}</Translation>,
      Paragraph2: <Translation>{(t) => t('level1SecondParagraph')}</Translation>,
      LastParagraph: <Translation>{(t) => t('level1ThirdParagraph')}</Translation>,
      CharacterImage: Queen,
      Svg2: <Level1SvgBottom />,
      theHash: 'level1',
    },
    {
      Svg1: <Level2SvgTop />,
      LevelNumber: '2',
      LevelTitle: <Translation>{(t) => t('letsGet')}</Translation>,
      LevelImage: Portal,
      Paragraph1: <Translation>{(t) => t('level2FirstParagraph')}</Translation>,
      Paragraph2: <Translation>{(t) => t('level2SecondParagraph')}</Translation>,
      CharacterImage: Rick,
      Svg2: <Level2SvgBottom />,
      theHash: 'level2',
    },
    {
      Svg1: <Level3SvgTop />,
      LevelNumber: '3',
      LevelTitle: <Translation>{(t) => t('halfwayThere')}</Translation>,
      LevelImage: Guntlet,
      Paragraph1: <Translation>{(t) => t('level3FirstParagraph')}</Translation>,
      Paragraph2: <Translation>{(t) => t('level3SecondParagraph')}</Translation>,
      LastParagraph: <Translation>{(t) => t('level3ThirdParagraph')}</Translation>,
      CharacterImage: Thanos,
      Svg2: <Level3SvgBottom />,
      theHash: 'level3',
    },
    {
      Svg1: <Level4SvgTop />,
      LevelNumber: '4',
      LevelTitle: <Translation>{(t) => t('almostDone')}</Translation>,
      LevelImage: Methf,
      Paragraph1: <Translation>{(t) => t('level4FirstParagraph')}</Translation>,
      Paragraph2: <Translation>{(t) => t('level4SecondParagraph')}</Translation>,
      CharacterImage: Break,
      Svg2: <Level4SvgBottom />,
      theHash: 'level4',
    },
    {
      Svg1: <Level5SvgTop />,
      LevelNumber: '5',
      LevelTitle: <Translation>{(t) => t('chitChatting')}</Translation>,
      LevelImage: Bazinga,
      Paragraph1: <Translation>{(t) => t('level4FirstParagraph')}</Translation>,
      CharacterImage: Sheldon,
      Svg2: <Level5SvgBottom />,
      theHash: 'level5',
    },
  ];
  return (
    <div className='Levels'>
      <Leveling
        ImgBG={BG2}
        Title={<Translation>{t => t('levelingTitle')}</Translation>}
        titleSpan={<Translation>{t => t('levelingTitleSpan')}</Translation>}
        titleSpanWeight='900'
        Paragraph={<Translation>{t => t('levelingFirstParagraph2')}</Translation>}
        paragraphSpan={<Translation>{t => t('levelingSpanParagraph')}</Translation>}
        // paragraphTransform='uppercase'
        svgFill='white'
        Gradient
      />
      {level.map((levels, i) => (
        <LevelMap key={i} {...levels} />
      ))}
      <JoinBanner />
    </div>
  );
}

export default Levels;

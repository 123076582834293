import React from 'react';
import TeacherCards from './cards/TeachersCards';
import TeacherMobile from './mobile/TeacherMobile';
import { Translation } from 'react-i18next';
// import Professor1 from '../../../assets/academy/teachers/Professor1.jpg';
// import Professor2 from '../../../assets/academy/teachers/Professor2.jpg';
// import Professor3 from '../../../assets/academy/teachers/Professor3.jpg';
// import Professor4 from '../../../assets/academy/teachers/Professor4.jpg';
// import Professor5 from '../../../assets/academy/teachers/Professor5.jpg';
// import Professor6 from '../../../assets/academy/teachers/Professor6.jpg';
import luisPic from '../../../assets/academy/teachers/luis.jpg';
import genesisPic from '../../../assets/academy/teachers/genesis.jpeg';
import alejandraPic from '../../../assets/academy/teachers/alejandra.png';
import julietPic from '../../../assets/academy/teachers/juliet.png';
import veronicaPic from '../../../assets/academy/teachers/veronica.jpeg';
// import femaleDefaultPicture from '../../../assets/academy/teachers/female-default.png';
import { 
  zpikDarkBlue, 
  zpikPink 
} from '../../../utils/color-palatte';
import { TeachersSVG1, TeachersSVG2 } from '../../../utils/SVGs/academy/Svgs';
import Carousel, { consts } from 'react-elastic-carousel';
// import SafeURL from '../../../utils/safe-url';

import './AcademyTeachers.css';

const AcademyTeachers = () => {
  const profession = [
    {
      TeacherPicture: luisPic,
      BorderColor: zpikDarkBlue,
      TeacherName: 'Luis Rosales',
      TeacherProfession: (
        <Translation>{(t) => t('luisResume')}</Translation>
      ),
    },
  ];

  const teacherArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? '❰' : '❱';
    return (
      <button
        className='Teachers__Carousel-btn'
        onClick={onClick}
        disabled={isEdge}
      >
        {pointer}
      </button>
    );
  };

  return (
    <Translation>
      {(t) => (
        <div className='Teachers__Container'>
          <TeachersSVG1 />
          <h1 className='Teachers__h1'>{t('zpikTeachers')}</h1>
          <h1 className='Teachers__h1-1'>{t('zTeachers')}</h1>
          <div className='Teachers__Cards-container'>
            {profession.map((professions) => (
              <TeacherCards {...professions} />
            ))}
          </div>
          <div className='Teachers__Movile-Cards-container'>
            <Carousel
              renderArrow={teacherArrow}
              renderPagination={({ pages, activePage, onClick }) => {
                return (
                  <div className='Teachers__Carousel-Flex'>
                    {pages.map((page) => {
                      const isActivePage = activePage === page;
                      return (
                        <div
                          className='Teachers__Carousel-dot'
                          key={page}
                          onClick={() => onClick(page)}
                          active={isActivePage}
                          style={
                            isActivePage
                              ? { borderColor: zpikPink }
                              : { borderColor: 'transparent' }
                          }
                        >
                          <span
                            className='Teachers__Carousel-Inactive'
                            style={
                              isActivePage
                                ? { display: 'none' }
                                : { display: 'block' }
                            }
                          ></span>
                          <span
                            className='Teachers__Carousel-Active'
                            style={
                              isActivePage
                                ? { display: 'block' }
                                : { display: 'none' }
                            }
                          ></span>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            >
              {profession.map((professions) => (
                <TeacherMobile {...professions} />
              ))}
            </Carousel>
          </div>
          {/* <SafeURL className='Teachers__Btn' text={t('showMore')} /> */}
          <TeachersSVG2 />
        </div>
      )}
    </Translation>
  );
};

export default AcademyTeachers;

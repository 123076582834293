import React from 'react';
import { Translation } from 'react-i18next';
import SafeURL from '../../../utils/safe-url';
import Neurons from '../../../assets/general/partner/Neurons.svg';
import NeuronsHover from '../../../assets/general/partner/NeuronsHover.svg';
import './PartnerBanner.css';

class PartnerBanner extends React.Component {
  state = {
    hover: false,
  };

  onHover = () => {
    this.setState({ hover: true });
  };

  onLeave = () => {
    this.setState({ hover: false });
  };

  render() {
    return (
      <Translation>
        {(t) => (
          <div className='PartnerBanner__Container'>
            <p>{t('madeBy')}</p>
            <SafeURL
              dangerousURL='https://neurons.agency'
              className='PartnerBanner__Url'
              newTab
              onMouseEnter={() => this.onHover()}
              onMouseLeave={() => this.onLeave()}
              text={
                <img
                  src={this.state.hover ? NeuronsHover : Neurons}
                  alt='neurons'
                  title='Neurons Agency'
                />
              }
            />
          </div>
        )}
      </Translation>
    );
  }
}

export default PartnerBanner;

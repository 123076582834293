import React from 'react';
import './Svgs.css';

// Level 1

export const Level1SvgBottom = () => (
  <>
    <svg
      className='Level1__SVG1-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 -1 A 54 145 0 0 0 101 -1 L 101 101 L -1 101z' />
    </svg>
    <svg
      className='Level1__SVG2-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 -1 A 55 120 0 0 0 101 -1 L 101 101 L -1 101z' />
    </svg>
  </>
);

// Level 2

export const Level2SvgTop = () => (
  <>
    <svg
      className='Level2__SVG1-Top'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 -1 A 130 180 0 0 1 101 101 L 101 -1 L -1 -1z' />
    </svg>
  </>
);

export const Level2SvgBottom = () => (
  <>
    <svg
      className='Level2__SVG1-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 98 A 160 160 -20 0 0 101 -1 L 101 101 L -1 101z' />
    </svg>
  </>
);

// Level 3

export const Level3SvgTop = () => (
  <>
    <svg
      className='Level3__SVG1-Top'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 40 A 70 53 0 1 1 101 101 L 101 -1 L -1 -1z' />
    </svg>
    <svg
      className='Level3__SVG2-Top'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 40 A 130 180 0 0 1 101 101 L 101 -1 L -1 -1z' />
    </svg>
  </>
);

export const Level3SvgBottom = () => (
  <>
    <svg
      className='Level3__SVG1-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 -1 A 52 80 -20 0 0 101 75 L 101 101 L -1 101z' />
    </svg>
    <svg
      className='Level3__SVG2-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 50 A 160 160 -20 0 0 101 -1 L 101 101 L -1 101z' />
    </svg>
  </>
);

// Level 4

export const Level4SvgTop = () => (
  <>
    <svg
      className='Level4__SVG1-Top'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 0 A 170 180 0 0 1 101 101 L 101 -1 L -1 -1z' />
    </svg>
  </>
);

export const Level4SvgBottom = () => (
  <>
    <svg
      className='Level4__SVG1-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 98 A 160 160 -20 0 0 101 -1 L 101 101 L -1 101z' />
    </svg>
  </>
);

// Level 5

export const Level5SvgTop = () => (
  <>
    <svg
      className='Level5__SVG1-Top'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 101 A 140 150 0 0 1 101 1 L 101 -1 L -1 -1z' />
    </svg>
  </>
);

export const Level5SvgBottom = () => (
  <>
    <svg
      className='Level5__SVG1-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 -1 A 58 120 -10 0 0 101 70 L 101 101 L -1 101z' />
    </svg>
  </>
);

export const JoinBannerSVGTop = () => (
  <>
    <svg
      className='JoinBanner__SVG1Top'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 50 A 70 80 0 0 0 101 50 L 101 -1 L -1 -1z' />
    </svg>
  </>
)

export const JoinBannerSVGBottom = () => (
  <>
    <svg
      className='JoinBanner__SVG1Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 50 A 70 80 0 0 1 101 50 L 101 101 L -1 101z' />
    </svg>
  </>
)
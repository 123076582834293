import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./en";
import es from "./es";
// the translations
// (tip move them in a JSON file and import them)
const resources = {
  es,
  en
};
// console.log("navigator.language: ", navigator.language);
// console.log("localStorage.getItem(language): ", localStorage.getItem("language"));
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem("language") === "us" ? "en" : localStorage.getItem("language") || (navigator.language === "en-US" ? "en" : "es"),

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;
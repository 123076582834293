import React from 'react'
import { css } from '@emotion/css'
import { zpikPink, BLACK, RED, WHITE } from './color-palatte'
import { Translation } from 'react-i18next';

const Input = (props) => {
  const input = css`
  background-color: ${WHITE};
`
  const labelStyles = css`
  
`

  const container = css`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: ${props.width || '100%'};
  `
  
  const inputContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: left;
`

  const pictureStyles = css`
  width: 40px;
  height: 30px;
  cursor: pointer;
`

  const redBorder = css`
  border: 4px solid ${RED} !important
`

  const {
    className,
    placeholder,
    value,
    onChange,
    name,
    label,
    labelStyle,
    pictureStyle,
    containerStyle,
    type,
    picture,
    isRequired,
    optional,
    rightPicture,
    showErrors,
    onKeyPress,
    disabled
  } = props;
  return (
    <Translation>
      {t =>
        <div className={`${container} ${containerStyle}`}>
          {label && <span style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            <p className={`${labelStyle} ${labelStyles}`} style={{ marginRight: 5, color: BLACK, fontWeight: 'bold' }}>{label}</p>
            {isRequired && !optional ? <span style={{ color: RED }}>*</span> : null}
            {!isRequired && optional ? <p style={{ color: BLACK }}>{`(${t('optional')})`}</p> : null}
          </span>}
          <div className={`${inputContainer}`}>
            {picture && <img src={picture} className={`${pictureStyles} ${pictureStyle}`} alt="" />}
            <input
              disabled={disabled}
              className={`${input} ${className} ${showErrors && isRequired && !value ? redBorder : ""}`}
              placeholder={placeholder}
              type={type || "text"}
              // style={picture ? { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 } : rightPicture ? { borderTopRightRadius: 0, borderBottomRightRadius: 0 } : {}}
              style={{ border: '4px solid', borderColor: zpikPink, fontFamily: 'Montserrat', borderRadius: '5px'}}
              value={value}
              onKeyPress={onKeyPress}
              onChange={onChange}
              name={name}
            />
            {rightPicture && rightPicture}
          </div>
        </div>}
    </Translation>
  )
}

export default Input;
const es = {
  translation: {
    // General
    'send': 'enviar',
    'internalError': 'Error interno del servidor.',
    'suggestionSended': 'Sugerencia enviada.',
    'contactEmailSended': 'Contacto Enviado.',
    // Header
    'home': 'Inicio',
    'academy': 'Escuela',
    'levels': 'Niveles',
    'contact': 'Contacto',
    // Home Banner
    'homeBannerTitle': 'EL CURSO DE INGLÉS QUE NECESITAS: ENTRETENIDO Y EFECTIVO.',
    'joinUs': 'únetenos',
    'HB-firstParagraph': 'Hello! Nos alegra ver esa motivación que tienes en aprender inglés, únete a Zpik Online y prueba nuestra metodología ágil, entretenida, personalizada e interactiva.',
    'HB-lastParagraph': '¿Te gustaría saber más?',
    'HB-button1': 'ver metodología',
    'HB-button2': 'unírme',
    // Home Levels
      //Cards
      'moreInfo': 'saber más...',
      'warmingUp': 'warming up',
      'letsGet': 'let\'s get',
      'halfwayThere': 'halfway there',
      'almostDone': 'almost done',
      'chitChatting': 'chit chatting',
    'levelsFeature': 'Aprende hablar en inglés, para ello solo necesitas hacer una evaluación de tus conocimientos con nosotros, luego en pocos minutos quedarás apuntado al  nivel que más se adapte a tu desempeño ¡y finalmente a ZPIKEAR!',
    'level1Text': 'Empiezas tu camino en el inglés. Congrats! \nComenzar a escuchar, comprender y amar el idioma; envolver tu vida o rutinas poco a poco in English será tu única tarea.📚',
    'level2Text': 'Podrás entender, leer y comprender un poco tus series y películas favoritas. No te detengas. Habla, piensa y actúa como ese personaje que te encanta lo haría, en inglés por su puesto 😉',
    'level3Text': 'Es el momento de olvidar el español por completo dentro de las clases. Si te equivocas y tienes un mal día: recuerda que todos hemos querido ser como Britney y raparnos. Pero el sol siempre sale cuando nos enfocamos en lo que queremos. ¡Sigue adelante!✨',
    'level4Text': 'Ok, en este punto puedes empezar a presumir que eres capaz de mantener una conversación con un nativo. Pero la práctica hizo al maestro. Y recuerda que ningún mar en calma hizo experto a un marinero, así que no dejes de practicar y aprender cada día, keep it up. 💪',
    'level5Text': 'Acá perderás el temor escénico, claro, si lo posees.  Te enfrentarás a tópicos de tu interés y conversaciones llenas de jocosidad junto a otros alumnos capacitados para entablar una conversación fluida. Let\'s talk coming soon with awesome Zpikers. 📣',
    // Home Video Banner
    'homeVideoTitle': 'Así logramos que puedas aprender inglés fácil y divertido.',
    'homeVideoSubtitle': 'Una metodología para no dejar de pensar y hablar en inglés desde el inicio.',
    'homeVideoFirstParagraph': 'En el momento que decides unirte tus días comenzarán a cambiar, porque para curiosos y aventureros como tú cada clase es un descubrimiento y suspenso que no deja de interesarte hasta encontrar lo que buscas... Hablar en inglés.',
    'homeVideoLastParagraph': 'No dejes esa meta para después, empieza ahora desde tu móvil, tablet o laptop y descubre el mundo que saber inglés trae para ti. Nosotros te ayudamos a alcanzar ese achievement que tanto anhelas.',
    // Home Carousel
    'realExperiences': '¿Qué piensan los ZPIKERS sobre nosotros?',
    // Leveling
    'levelingTest': 'Test de Nivelación',
    'levelingFirstParagraph': 'Al completar este examen podrás saber en que nivel has quedado para continuar tu aprendizaje del idioma',
    'levelingSubheading': '¡Unete a la Aventura!',
    'performTest': 'Realizar Test',
      // Levels Version
      'levelingTitle': 'Todos los niveles para',
      'levelingTitleSpan': 'aprender inglés fácil',
      'levelingFirstParagraph2': 'por medio de un programa educacional interactivo, viviras un journey de aprendizaje al cien. te aseguramos que:',
      'levelingSpanParagraph': '¡Te divertiras un monton!',
    // JoinBanner
    'JoinButton1': 'si, quiero conocerlos',
    'JoinButton2': 'quiero realizar nivelacion',
    // ContactUs
    'becomeAZpiker': 'Conviértete en ZPIKER',
    'writeUs': 'escribenos',
    'PHolder1': 'Nombre',
    'PHolder2': 'Correo',
    'PHolder3': 'Mensaje',
    // Footer
    'copy': '2021 Zpik Online | Todos los derechos reservados',
    'madeBy': 'Hecho Por',
    // Academy
    'about': 'Somos la academia de inglés online más divertida.',
    'aboutFirstText': 'Te ayudaremos a que logres la fluidez y comprensión del idioma más hablado en el mundo, nuestra nueva metodología interactiva a distancia, harán que te quedes  y seas parte de la comunidad ZPIKER.',
    'aboutSecondText': 'Contamos con profesores acreditados, 100% bilingües  y 1000% cheveres  para hacer de tu aprendizaje una experiencia entretenida y efectiva digna de recordar toda una vida.',
    'aboutThirdText': 'SOMOS ZPIK, SOMOS ZPIKERS. ¡UNETENOS!',
    'courseMethodology': 'Metodologia del Curso',
    'zpikTeachers': 'profesores de zpik',
    'zTeachers': 'profesores',
    'degreeInLanguages': 'graduado en idiomas',
    'showMore': 'mostrar mas...',
    'luisResume': "¡Bienvenidos a Zpik, queridos futuros estudiantes! Mi nombre es Luis Rosales, soy un profesional joven pero experimentado con más de 4 años de experiencia, y enseñar inglés fue lo primero que hice para ganarme la vida. Disfruto de una variedad de materias, que incluyen literatura, ciencia, historia y lingüística, y me esfuerzo por convertirme en políglota en un futuro cercano. ¡Divirtámonos juntos mientras aprenden y se convierten en los hablantes de inglés fluido que aspiran a ser!",
    // Levels
      // Level1
      'level1FirstParagraph': 'Welcome, welcome. En este nivel te damos la bienvenida y te incentivamos a sumergirte en esta nueva maravillosa cultura anglosajona; además de hablar en inglés, una herramienta imprescindible para tu desarrollo individual e intelectual, con el que podrás expandir tus oportunidades profesionales y catálogo de trabajo a nivel profesional, o quizás para diversificar tu empresa.',
      'level1SecondParagraph': 'El inglés, es incluso clave al momento de comunicarte con cualquier otra persona proveniente de muchos países. Pues aparte de ser catalogado “el principal idioma internacional”, es un vocablo que resulta muy dinámico y sencillo de adoptar.',
      'level1ThirdParagraph': 'En todo momento ZPIK Online te ayudará en este nivel a adentrarte en lo elemental, y lo haremos usando una metodología increíblemente efectiva con material certificado, de primera calidad y absolutamente completo para que empieces esta aventura de aprender un nuevo idioma, partiendo de tu medio de entretenimiento favorito; ya sea el cine, la tv, los libros y hasta la industria musical (o de todo un poco.)',
      // Level2
      'level2FirstParagraph': 'El objetivo de este nivel es aprender en inglés nuevas culturas, reforzar o repasar todo lo básico y no dejar en ningún momento de aprender nuevas y mejores cosas. Cada día leerás, escribirás y comprenderás mejor. En este punto el inglés para ti se convertirá en algo inolvidable en cada clase. ¿De qué manera? Dándote numerosas y amplias maneras de practicar, usando el contexto para aplicar cada herramienta gramatical nueva, vocabulario aprendido y lo mejor de todo… Sabrás las maneras en las que se debe pronunciar, hablar y comunicarse de forma general.  Tal cual como si estuvieras viviendo una situación parecida a tu personaje favorito.',
      'level2SecondParagraph': 'Te divertiras pero aprenderás sin parar, pues cada unidad te proporcionará la integralidad y certeza de un aprendizaje efectivo, divertido, innovador y sobre todo útil. Con ZPIK Online, constantemente podrás comprobar tu avance y mejora en la lengua Inglesa, además recuerda que siempre estarás usando lenguaje real y común.',
      // Level 3
      'level3FirstParagraph': 'You get better every-day. Ya sea que empieces por justo en este nivel o hayas avanzado con nosotros; lo importante es que estás a la mitad del camino (relativamente).',
      'level3SecondParagraph': 'Es importante recordar que cuando se aprende un nuevo idioma, es como nacer de nuevo y tenemos que aprender a comunicarnos, comprender y reconocer cada parte de este novedoso vocablo que llegó a nuestra vida. Entonces, algo que es clave e importante: THE SKY\'S THE LIMIT.',
      'level3ThirdParagraph': '¡La rapidez y efectividad de tu progreso está en tus manos! Recuerda que tomaste la decisión correcta, y esa ha sido aprender y confiar en ZPIK Online, así que no te limites ni te impacientes. Lo has venido haciendo bien, lo estás haciendo bien y lo crucial aquí es que lo harás mejor en casa step. La práctica es increíblemente relevante para continuar tu formación en el inglés, por eso estamos aquí, para que sigas creciendo y amando esta cultura tan linda de nuestra mano. Continúa tu formación como Anglo-parlante.',
      // Level4
      'level4FirstParagraph': 'En este nivel ya casi estás dentro del idioma, solo falta continuar porque nunca terminamos de aprender y mucho menos, cuando se trata de un nuevo lenguaje. Mientras más interés tomes sobre aprender inglés fácil, más rápido continuarás creando ese espacio dentro de tu vida.',
      'level4SecondParagraph': 'Este es el punto en donde tú solo (y con apoyo e incentivo de tu teacher)  dejas de asociar cada palabra directamente desde el español. Ya conoces bastante para dejar de buscar traducciones. Acá, ya tendrás  la capacidad de comprender el significado de un nuevo término solo escuchando su definición y descripción.',
      // Level5
      'level5FirstParagraph': 'Perderás poco a poco el temor escénico para comunicarte libremente, si es que posees dicho temor. Seguirás complementando tu nivel gramatical, también reforzaras y aprenderás datos interesantes conversando acerca de una diversidad de temas. No olvides tu método de entretenimiento favorito, pues será aquí donde lo usarás con mayor facilidad para compartirlo con los demás. Y tenemos la seguridad de que tendrás todas las herramientas para mantener un diálogo como tu personaje anglosajón favorito lo haría.',
    // Contact
    'findUs': 'encuentranos',
    'rightHere': 'justo aqui',
    'socialMediaContact': 'redes sociales \ncontactos',
    // Suggestions
    'suggestions': 'sugerencias',
    'issue': 'Asunto',
    'emailIsInvalid': 'Email is not valid.',
    'nelio': 'Excelente el trabajo que estan haciendo para que el idioma nos sea mucho más fácil de entender y útil de aplicar en nuestra vida diaria.'
  }
}

export default es
import React, { Component } from 'react'
import { companyEmail, contactNumber } from '../config'
const URL = require('url-parse')

class SafeURL extends Component {
  isSafe(dangerousURL, text) {
    const url = URL(dangerousURL, {})
    if (url.protocol === 'http:') return true
    if (url.protocol === 'https:') return true
    if (url.protocol === 'tel:' && url.pathname === contactNumber) return true;
    if (url.protocol === 'mailto:' && url.pathname === companyEmail)
      return true;
    // console.log(url);
    return false
  }
  render() {
    const dangerousURL = this.props.dangerousURL
    const safeURL = this.isSafe(dangerousURL) ? dangerousURL : null
    // eslint-disable-next-line
    return <a
      className={this.props.className}
      href={safeURL}
      onClick={this.props.onClick}
      target={this.props.newTab ? "_blank" : ""}
      rel={this.props.newTab ? "noopener noreferrer" : ""}
      style={this.props.style}
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseLeave}
      onMouseDown={this.props.onMouseDown}
      onMouseUp={this.props.onMouseUp}
    >
      {this.props.text}
    </a>
  }
}

export default SafeURL;


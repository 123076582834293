import React, { useEffect, useState } from 'react';
import ActionButton from '../../../utils/action-button/ActionButton';
import Ancestral from '../../../assets/general/leveling/Ancestral-min.png';
import AncestralShine from '../../../assets/general/leveling/Ancestral_Shine.png';
import AncestralShadow from '../../../assets/general/leveling/Ancestral_Shadow.png';
import Dormammu from '../../../assets/general/leveling/Dormamus_Logo.png';
import { zpikPink, zpikYellow } from '../../../utils/color-palatte';
import { Translation } from 'react-i18next';
import './Leveling.css';
import {
  LevelingSVGBottom,
  LevelingSVGTop,
} from '../../../utils/SVGs/general/Svgs';
import { levelingMsgEn, levelingMsgEs } from '../../../config';

const TransMutation = ({ styles, Id }) => {
  return (
    <div className='Magic__Container' style={styles} id={Id}>
      <div className='Magic__Circle1'>
        <div className='Magic__Circle2'>
          <div className='Magic__Circle3'></div>
        </div>
      </div>
    </div>
  );
};

const Leveling = ({
  ImgBG,
  Title,
  titleSpan,
  titleSpanWeight,
  titlePaddingTop,
  Paragraph,
  paragraphSpan,
  subHeading,
  titleFontWeight,
  titleFontSize,
  titleTextTransform,
  paragraphSize,
  paragraphTransform,
  paragraphWeight,
  Gradient,
  SVGTop,
  svgFill,
}) => {
  const [shine, setShine] = useState(false);

  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 650px)').matches
  );

  useEffect(() => {
    const handler = (e) => setMatches(e.matches);
    window.matchMedia('(max-width: 650px)').addListener(handler);
  }, [matches]);

  const language =
    localStorage.getItem('language') === 'us'
      ? 'en'
      : localStorage.getItem('language') || 'es';

  return (
    <Translation>
      {(t) => (
        <div
          className='Leveling__Container'
          style={{ backgroundImage: `url(${ImgBG})` }}
        >
          {Gradient && (
            <div
              style={{
                background: 'linear-gradient(180deg, #fff, transparent)',
                height: '300px',
                position: 'absolute',
                top: '0',
                left: '0',
                width: '100%',
              }}
            />
          )}
          {SVGTop && <LevelingSVGTop fill={svgFill} />}
          <div className='Leveling__Content' style={{ paddingTop: titlePaddingTop }}>
            {Title && (
              <h1
                className='Leveling__Title'
                style={{
                  fontWeight: titleFontWeight,
                  fontSize: titleFontSize,
                  textTransform: titleTextTransform,
                }}
              >
                {Title}
                {titleSpan && (
                  <span style={{ fontWeight: titleSpanWeight || 'bold' }}>
                    {' '}
                    {titleSpan}
                  </span>
                )}
              </h1>
            )}
            {Paragraph && (
              <>
                <p
                  style={{
                    fontSize: paragraphSize,
                    textTransform: paragraphTransform,
                    fontWeight: paragraphWeight,
                  }}
                >
                  {t('levelingFirstParagraph')}
                </p>
                {paragraphSpan && (
                  <p style={{ fontWeight: 'bold', textTransform: 'uppercase' }}> {paragraphSpan}</p>
                )}
              </>
            )}
            {subHeading && (
              <h3 className='Leveling__SubTitle'>{t('levelingSubheading')}</h3>
            )}
            <ActionButton
              ButtonClass='url'
              TextContent={t('performTest')}
              Padding='10px'
              FontSize='1.5em'
              TextTransform='uppercase'
              Width='240px'
              Height='60px'
              BGColor={zpikPink}
              BGColorHover={zpikYellow}
              Margin='30px 0'
              hov={() => {
                setShine(true);
              }}
              unhov={() => {
                setShine(false);
              }}
              ZIndex='10'
              SafeUrl={language === 'es' ? levelingMsgEs : levelingMsgEn}
              UrlTarget={true}
            />
          </div>
          <img
            className='Ancestral'
            style={shine ? { opacity: '0' } : { opacity: '1' }}
            src={Ancestral}
            alt='Ancestral'
          />
          <img
            className='Ancestral'
            style={shine ? { opacity: '1' } : { opacity: '0' }}
            src={AncestralShine}
            alt='AncestralShine'
          />
          <img
            className='Ancestral__Shadow'
            src={AncestralShadow}
            alt='AncestralShadow'
          />
          <img
            className='Dormammu'
            src={Dormammu}
            alt='Dormammu_Logo'
            style={shine ? { opacity: '1' } : { opacity: '0' }}
          />
          <TransMutation
            Id='Power1'
            styles={shine ? { opacity: '1' } : { opacity: '0' }}
          />
          <TransMutation
            Id='Power2'
            styles={shine ? { opacity: '1' } : { opacity: '0' }}
          />
          <LevelingSVGBottom fill={svgFill} />
        </div>
      )}
    </Translation>
  );
};

export default Leveling;

import React from 'react'
import './Svgs.css';

// Find Us

export const FindUsSvgBottom = () => (
  <>
    <svg
      className='FindUs__SVG1-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M -1 60 A 70 120 0 0 0 101 -1 L 101 101 L -1 101z' />
    </svg>
    <svg
      className='FindUs__SVG2-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M 0 0 A 120 100 0 0 0 100 0 L 101 101 L -1 101z' />
    </svg>
  </>
);

// Social Contact

export const SocialContactSvgTop = () => (
  <>
    <svg
      className='SocialContact__SVG1-Top'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M 0 100 A 60 180 0 0 1 100 100 L 101 -1 L -1 -1z' />
    </svg>
    
    <svg
      className='SocialContact__SVG2-Top'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M 0 100 A 60 80 0 0 1 100 100 L 101 -1 L -1 -1z' />
    </svg>
  </>
);

export const SocialContactSvgBottom = () => (
  <>
    <svg
      className='SocialContact__SVG1-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M 0 0 A 60 180 0 0 0 100 0 L 101 101 L -1 101z' />
    </svg>
    <svg
      className='SocialContact__SVG2-Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
    >
      <path d='M 0 0 A 60 80 0 0 0 100 0 L 101 101 L -1 101z' />
    </svg>
  </>
);

// Suggestions

export const SuggestionsSvgTop = () => (
  <svg
    className='Suggestion__SVG-Top'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 70 A 68 120 0 0 1 101 30 L 101 -1 L -1 -1z' />
  </svg>
);

export const SuggestionsSvgBottom = () => (
  <svg
    className='Suggestion__SVG-Bottom'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 40 A 85 120 0 0 0 101 90 L 101 101 L -1 101z' />
  </svg>
);
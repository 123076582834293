import React from 'react'
import { css } from '@emotion/css'
import { BLACK, RED, WHITE, zpikPink } from './color-palatte'
import { Translation } from 'react-i18next';

const TextArea = (props) => {
  const input = css`
  background-color: ${WHITE};
  resize: none;
  overflow: auto;
`
  const labelStyles = css`
  
`

  const container = css`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  width: ${props.width || '100%'};
`

  const inputContainer = css`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: left;
`

  const redBorder = css`
  border: 4px solid ${RED} !important
`

  const {
    className,
    placeholder,
    value,
    onChange,
    name,
    label,
    labelStyle,
    containerStyle,
    type,
    isRequired,
    optional,
    showErrors,
    onKeyPress,
    disabled,
    rows,
    cols,
  } = props;
  return (
    <Translation>
      {t =>
        <div className={`${container} ${containerStyle}`}>
          {label && <span style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
            <p className={`${labelStyle} ${labelStyles}`} style={{ marginRight: 5, color: BLACK }}>{label}</p>
            {isRequired && !optional ? <span style={{ color: RED }}>*</span> : null}
            {!isRequired && optional ? <p style={{ color: BLACK }}>{`(${t('optional')})`}</p> : null}
          </span>}
          <div className={`${inputContainer}`}>
            <textarea
              rows={rows}
              cols={cols}
              disabled={disabled}
              className={`${input} ${className} ${showErrors && !value ? redBorder : ""}`}
              style={{ border: '4px solid', borderColor: zpikPink, fontFamily: 'Montserrat', borderRadius: '5px'}}
              placeholder={placeholder}
              type={type || "text"}
              value={value}
              onKeyPress={onKeyPress}
              onChange={onChange}
              name={name}
            />
          </div>
        </div>}
    </Translation>
  )
}

export default TextArea;
import React, { Component } from 'react'
import { Link } from 'react-router-dom'

class SafeLINK extends Component {
  isSafe(dangerousLINK) {
    const url = dangerousLINK
    if (url === '/') return true
    if (url === '/home') return true
    if (url === '/levels') return true
    if (url === '/levels#level1') return true
    if (url === '/levels#level2') return true
    if (url === '/levels#level3') return true
    if (url === '/levels#level4') return true
    if (url === '/levels#level5') return true
    if (url === '/academy') return true
    if (url === '/academy#feature_video') return true
    if (url === '/contact') return true
    // console.log(url);
    return false
  }
  render() {
    const dangerousLINK = this.props.dangerousLINK
    const safeLINK = this.isSafe(dangerousLINK) ? dangerousLINK : ''
    return <Link
      className={this.props.className}
      to={safeLINK}
      onClick={this.props.onClick}
      target={this.props.newTab ? "_blank" : ""}
      rel={this.props.newTab ? "noopener norefer" : ""}
      style={this.props.style}
      onMouseEnter={this.props.onMouseEnter}
      onMouseLeave={this.props.onMouseLeave}
      onMouseDown={this.props.onMouseDown}
      onMouseUp={this.props.onMouseUp}
    >
      {this.props.text}
    </Link>
  }
}

export default SafeLINK;
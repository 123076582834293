import React from 'react';
import './Svgs.css';

export const ContactUsSVG1 = () => (
  <svg
    className='ContactUS-SVG1'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 70 A 68 120 0 0 1 101 30 L 101 -1 L -1 -1z' />
  </svg>
);

export const ContactUsSVG2 = () => (
  <svg
    className='ContactUS-SVG2'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 40 A 85 120 0 0 0 101 90 L 101 101 L -1 101z' />
  </svg>
);

export const LevelingSVGTop = ({ fill }) => (
  <>
    <svg
      className='Leveling__SVG1Top'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
      style={{fill: fill}}
    >
      <path d='M -1 50 A 70 80 0 0 0 101 50 L 101 -1 L -1 -1z' />
    </svg>
  </>
)

export const LevelingSVGBottom = ({ fill }) => (
  <>
    <svg
      className='Leveling__SVG1Bottom'
      viewBox='0 0 100 100'
      preserveAspectRatio='none'
      style={{fill: fill}}
    >
      <path d='M -1 50 A 70 80 0 0 1 101 50 L 101 101 L -1 101z' />
    </svg>
  </>
)

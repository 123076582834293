import React from 'react';
import { Translation } from 'react-i18next';
import { emailsRegex } from '../../../utils/regex';
import { postData } from '../../../fetch/index';
import Alert from './alert/Alert';
import Input from '../../../utils/Input';
import TextArea from '../../../utils/TextArea';
// import SendButton from '../../../utils/send-button/SendButton';
import GrootImg from '../../../assets/general/Groot.png';
import Speaker from '../../../assets/general/speaker.svg';
import Globe from '../../../assets/general/bubbleWithLogo.svg';
import UIfx from 'uifx';
import Song from '../../../assets/general/iZpik.mp3';
import Song2 from '../../../assets/general/iZpik2.mp3';
import { ContactUsSVG1, ContactUsSVG2 } from '../../../utils/SVGs/general/Svgs';
import { Loader1 } from '../../../utils/loaders/loaders';

import './ContactUs.css';
import ActionButton from '../../../utils/action-button/ActionButton';

class ContactUs extends React.Component {
  state = {
    contactLoading: false,
    contactMessage: '',
    contactEmail: '',
    contactName: '',
    changeSound: true,
    firstMatches: window.matchMedia(
      '(max-width: 1060px) and (min-width: 1051px)'
    ).matches,
    secondMatches: window.matchMedia(
      '(max-width: 1050px) and (min-width: 971px)'
    ).matches,
    thirdMatches: window.matchMedia('(max-width: 970px) and (min-width: 801px)')
      .matches,
    fourthMatches: window.matchMedia(
      '(max-width: 800px) and (min-width: 641px)'
    ).matches,
    fifthMatches: window.matchMedia('(max-width: 640px)').matches,
  };

  componentDidMount = () => {
    const handler = (e) => this.setState({ firstMatches: e.matches });
    window
      .matchMedia('(max-width: 1060px) and (min-width: 1051px)')
      .addListener(handler);
    const handler2 = (e) => this.setState({ secondMatches: e.matches });
    window
      .matchMedia('(max-width: 1050px) and (min-width: 971px)')
      .addListener(handler2);
    const handler3 = (e) => this.setState({ thirdMatches: e.matches });
    window
      .matchMedia('(max-width: 970px) and (min-width: 801px)')
      .addListener(handler3);
    const handler4 = (e) => this.setState({ fourthMatches: e.matches });
    window
      .matchMedia('(max-width: 800px) and (min-width: 641px)')
      .addListener(handler4);
    const handler5 = (e) => this.setState({ fifthMatches: e.matches });
    window.matchMedia('(max-width: 640px)').addListener(handler5);
  };

  bell = () => {
    /**
     * Creating new object for the function
     *
     * Create a funtion for make alternate sounds
     */

    if (this.state.changeSound) {
      const beep = new UIfx(Song, {
        volume: 0.5,
        throttleMs: 100,
      });
      beep.play();
      this.setState({ changeSound: false });
    }

    if (!this.state.changeSound) {
      const beep = new UIfx(Song2, {
        volume: 0.5,
        throttleMs: 100,
      });
      beep.play();
      this.setState({ changeSound: true });
    }
  };

  onContact = (event, t) => {
    event.preventDefault();
    if (
      this.state.contactEmail.match(emailsRegex) &&
      this.state.contactMessage
    ) {
      this.setState({ contactLoading: true }, async () => {
        try {
          const data = {
            name: this.state.contactName,
            message: this.state.contactMessage,
            email: this.state.contactEmail,
          };
          const response = await postData('/contact/create-contact/', data, false);
          console.log("onContact / response: ", response);
          this.showAlert(true, t('contactEmailSended'), true);
          this.setState({
            contactLoading: false,
            contactName: '',
            contactMessage: '',
            contactEmail: '',
            showInputsErrorContact: false,
          });
        } catch (e) {
          console.log("onContact / error: ", e);
          const error =
            e && e.data && e.data.error && e.data.error.message
              ? e.data.error.message === "internal-error"
                ? t('internalError')
                : e.data.error.message
              : t('internalError');
          this.showAlert(true, error, false);
          this.setState({ contactLoading: false });
        }
      });
    } else {
      this.setState({ showInputsErrorContact: true });
      if (
        this.state.contactEmail &&
        !this.state.contactEmail.match(emailsRegex)
      ) {
        this.showAlert(true, t('emailIsInvalid'), false);
      }
    }
  };

  handleInputChange = ({ target }) => {
    this.setState({
      [target.name]: target.value,
    });
  };

  showAlert = (isOpen, content, success) =>
    this.setState({
      alertSuccess: success,
      alertContent: content,
      isAlertOpen: isOpen,
    });

  render() {
    const { alignButton, title, RenderSVGTop, RenderSVGBottom } = this.props;
    // console.log("this.state: ", this.state);
    return (
      <Translation>
        {(t) => (
          <>
            <section
              className='App-ContactUs'
              style={
                !RenderSVGTop
                  ? this.state.firstMatches
                    ? this.state.secondMatches
                      ? this.state.thirdMatches
                        ? this.state.fourthMatches
                          ? this.state.fifthMatches
                            ? { paddingTop: '30px' }
                            : { paddingTop: '30px' }
                          : { paddingTop: '30px' }
                        : { paddingTop: '30px' }
                      : { paddingTop: '60px' }
                    : { paddingTop: '60px' }
                  : { paddingTop: '150px' }
              }
            >
              {RenderSVGTop ? <ContactUsSVG1 /> : undefined}
              <h1>{title}</h1>
              <div className='Contact__formContainer'>
                <form
                  className='message'
                  noValidate
                  autoComplete='off'
                  onSubmit={(e) => this.onContact(e, t)}
                  style={{ alignItems: alignButton }}
                >
                  <Input
                    placeholder={t('PHolder1')}
                    type='text'
                    width='100%'
                    // className={`${firstInputContainer1}`}
                    value={this.state.contactName}
                    onChange={this.handleInputChange}
                    name='contactName'
                    disabled={this.state.contactLoading}
                  />
                  <Input
                    placeholder={t('PHolder2')}
                    type='text'
                    // className={`${firstInputContainer2}`}
                    width='100%'
                    value={this.state.contactEmail}
                    showErrors={this.state.showInputsErrorContact}
                    onChange={this.handleInputChange}
                    name='contactEmail'
                    disabled={this.state.contactLoading}
                    isRequired
                  />
                  <TextArea
                    rows='4'
                    cols='15'
                    placeholder={t('PHolder3')}
                    name='contactMessage'
                    showErrors={this.state.showInputsErrorContact}
                    className='MsgBox'
                    onChange={this.handleInputChange}
                    value={this.state.contactMessage}
                    disabled={this.state.contactLoading}
                    isRequired
                  />
                  <ActionButton
                    ButtonClass='button'
                    ButtonType='submit'
                    Padding='10px'
                    Width='180px'
                    Height='50px'
                    FontWeight='700'
                    TextTransform='uppercase'
                    BorderRadius='8px'
                    FontSize='1.9em'
                    TextContent={t('send')}
                    Position='relative'
                    RenderDisabled={<Loader1 />}
                    Disabled={this.state.contactLoading}
                  />
                  {/* <SendButton
                    type='submit'
                    disabled={this.state.contactLoading}
                  /> */}
                </form>
                <div
                  className='Contact__Imgs-container'
                  title='click me!'
                  onClick={this.bell}
                >
                  <img src={GrootImg} alt='Groot' className='Contact__image' />
                  <img src={Globe} alt='globe' className='Contact__img-globe' />
                  <button className='Contact__speak-btn'>
                    <img
                      src={Speaker}
                      alt='spkLogo'
                      className='btn__speakLogo'
                    />
                  </button>
                </div>
              </div>
              {RenderSVGBottom ? <ContactUsSVG2 /> : undefined}
            </section>
            <Alert
              isOpen={this.state.isAlertOpen}
              onRequestClose={() => this.setState({ isAlertOpen: false })}
              content={this.state.alertContent}
              success={this.state.alertSuccess}
            />
          </>
        )}
      </Translation>
    );
  }
}

export default ContactUs;

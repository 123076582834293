const en = {
  translation: {
    // General
    'send': 'send',
    'internalError': 'Internal server error.',
    'suggestionSended': 'Suggestion Sended.',
    'contactEmailSended': 'Contact Sended.',
    // Header
    'home': 'Home',
    'academy': 'Academy',
    'levels': 'Levels',
    'contact': 'Contact',
    // Home Banner
    'homeBannerTitle': 'THE ENGLISH COURSE YOU NEED: ENTERTAINING AND EFFECTIVE.',
    'HB-firstParagraph': 'Hello! We are glad to see that motivation you have in learning English, join Zpik Online and try our agile, entertaining, personalized and interactive methodology.',
    'HB-lastParagraph': 'Would you like to know more?',
    'HB-button1': 'see methodology',
    'HB-button2': 'join',
    // Home Levels
    //Cards
    'moreInfo': 'more info',
    'warmingUp': 'warming up',
    'letsGet': 'let\'s get',
    'halfwayThere': 'halfway there',
    'almostDone': 'almost done',
    'chitChatting': 'chit chatting',
    'levelsFeature': 'Learn to speak in English, for this you only need to do an evaluation of your knowledge with us, then in a few minutes you will be targeted at the level that best suits your performance, and finally to ZPIKEAR!',
    'level1Text': 'You start your way in English. Congrats! \nBegin to listen, understand and love the language; wrapping your life or routines little by little in English will be your only task.📚',
    'level2Text': 'You will be able to understand, read and understand a little your favorite series and movies. Do not stop. Speak, think and act like that character that you love would, in English of course 😉',
    'level3Text': 'It is the moment to forget Spanish completely within the classes. If you mess up and have a bad day: remember that we have all wanted to be like Britney and shave. But the sun always rises when we focus on what we want. Keep going! ✨',
    'level4Text': 'Ok, at this point you can start to presume that you are able to carry on a conversation with a native speaker. But practice made perfect. And remember that no calm sea made a sailor an expert, so don\'t stop practicing and learning every day, keep it up. 💪',
    'level5Text': 'Here you will lose your stage fright, of course, if you have it. You will face topics of your interest and conversations full of humor with other students who are able to establish a fluent conversation. Let\'s talk coming soon with awesome Zpikers. 📣',
    // Home Video Banner
    'homeVideoTitle': 'This way we achieve that you can learn English easy and fun.',
    'homeVideoSubtitle': 'A methodology to keep thinking and speaking in English from the beginning.',
    'homeVideoFirstParagraph': 'The moment you decide to join, your days will begin to change, because for curious and adventurers like you, each class is a discovery and suspense that never ceases to interest you until you find what you are looking for ... Speak in English.',
    'homeVideoLastParagraph': 'Do not leave that goal for later, start now from your mobile, tablet or laptop and discover the world that knowing English brings to you. We help you achieve that achievement that you long for.',
    // Home Carousel
    'realExperiences': 'What do the ZPIKERS think about us?',
    // Leveling
    'levelingTest': 'Leveling Test',
    'levelingFirstParagraph': 'By completing this exam you will be able to know what level you have remained to continue your learning of the language',
    'levelingSubheading': 'Join the Adventure!',
    'performTest': 'Perform Test',
    // Levels Version
    'levelingTitle': 'All levels to learn',
    'levelingTitleSpan': 'english easy',
    'levelingFirstParagraph2': 'Through an interactive educational program, you will live a full learning journey. We assure you that:',
    'levelingSpanParagraph': 'You will have a lot of fun!',
    // JoinBanner
    'JoinButton1': 'yes, I want to meet them',
    'JoinButton2': 'I want to take the leveling test',
    // ContactUs
    'becomeAZpiker': 'Become a ZPIKER',
    'writeUs': 'write us',
    'PHolder1': 'Name',
    'PHolder2': 'E-mail',
    'PHolder3': 'Message',
    // Footer
    'copy': '2020 Zpik Online | All Rights reserved',
    'madeBy': 'Made By',
    // Academy
    'about': 'We are the most fun online English academy.',
    'aboutFirstText': 'We will help you to achieve fluency and understanding of the most spoken language in the world, our new interactive remote methodology will make you stay and be part of the ZPIKER community.',
    'aboutSecondText': 'We have accredited, 100% bilingual and 1000% experienced teachers to make your learning an entertaining and effective experience worth remembering for a lifetime.',
    'aboutThirdText': 'WE ARE ZPIK, WE ARE ZPIKERS. JOIN US!',
    'courseMethodology': 'Course Methodology',
    'zpikTeachers': 'zpik teachers',
    'zTeachers': 'teachers',
    'degreeInLanguages': 'degree in languages',
    'showMore': 'show more...',
    'luisResume': "Welcome to Zpik, dear students-to-be! My name is Luis Rosales, I'm a young but seasoned professional with 4+ years of experience, and teaching English was the very first thing I ever did for a living. I enjoy a variety of subjects, including literature, science, history, and linguistics, and I strive to become a polyglot in the near future. Let's have fun together as you learn and grow into the fluent English speakers you aspire to be!",
    // Levels
    // Level1
    'level1FirstParagraph': 'Welcome, welcome. At this level we welcome you and encourage you to immerse yourself in this wonderful new Anglo-Saxon culture; In addition to speaking in English, an essential tool for your individual and intellectual development, with which you can expand your professional opportunities and job catalog at a professional level, or perhaps to diversify your company.',
    'level1SecondParagraph': 'English is even key when communicating with any other person from many countries. Well, apart from being cataloged "the main international language", it is a word that is very dynamic and easy to adopt.',
    'level1ThirdParagraph': 'At all times ZPIK Online will help you at this level to delve into the basics, and we will do so using an incredibly effective methodology with certified, top quality and absolutely complete material so that you can start this adventure of learning a new language, starting from your medium of favorite entertainment; be it cinema, tv, books and even the music industry (or a bit of everything.)',
    // Level2
    'level2FirstParagraph': 'The objective of this level is to learn new cultures in English, reinforce or review all the basics and never stop learning new and better things. Every day you will read, write and understand better. At this point, English for you will become unforgettable in each class. How? Giving you numerous and extensive ways to practice, using the context to apply each new grammar tool, vocabulary learned and best of all… You will know the ways in which to pronounce, speak and communicate in a general way. As if you were living a situation similar to your favorite character.',
    'level2SecondParagraph': 'You will have fun but you will learn without stopping, because each unit will provide you with the comprehensiveness and certainty of an effective, fun, innovative and above all useful learning. With ZPIK Online, you can constantly check your progress and improvement in the English language, also remember that you will always be using real and common language.',
    // Level 3
    'level3FirstParagraph': 'You get better every-day. Whether you start right at this level or have advanced with us; the important thing is that you are halfway there (relatively).',
    'level3SecondParagraph': 'It is important to remember that when you learn a new language, it is like being born again and we have to learn to communicate, understand and recognize each part of this new word that came into our lives. So, something that is key and important: THE SKY\'S THE LIMIT.',
    'level3ThirdParagraph': 'The speed and effectiveness of your progress is in your hands! Remember that you made the right decision, and that has been to learn and trust ZPIK Online, so do not limit yourself or get impatient. You have been doing it well, you are doing it well and the crucial thing here is that you will do better at home step. Practice is incredibly relevant to continue your training in English, that is why we are here, so that you continue to grow and love this beautiful culture from our hand. Continue your training as an Anglo-speaker.',
    // Level4
    'level4FirstParagraph': 'At this level you are almost into the language, we just need to continue because we never finish learning, much less when it comes to a new language. The more interest you take in learning easy English, the faster you will continue to create that space in your life.',
    'level4SecondParagraph': 'This is the point where you alone (and with the support and encouragement of your teacher) stop associating each word directly from Spanish. You already know enough to stop looking for translations. Here, you will already have the ability to understand the meaning of a new term just by listening to its definition and description.',
    // Level5
    'level5FirstParagraph': 'You will gradually lose stage fright to communicate freely, if you have such fear. You will continue to complement your grammar level, you will also reinforce and learn interesting facts by talking about a variety of topics. Do not forget your favorite entertainment method, as it will be here where you will most easily use it to share it with others. And we are sure that you will have all the tools to maintain a dialogue as your favorite Anglo-Saxon character would.',
    // Contact
    'findUs': 'find us',
    'rightHere': 'rigth here',
    'socialMediaContact': 'social media \ncontacts',
    // Suggestions
    'suggestions': 'suggestions',
    'issue': 'Issue',
    'emailIsInvalid': 'Correo no es valido.',
    'nelio': 'Excellent work they are doing to make the language much easier for us to understand and useful to apply in our daily lives.'
  }
}

export default en
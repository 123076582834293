import React from 'react';
import { Translation } from 'react-i18next';
import LevelCards from './cards/LevelCards';
import './HomeLevels.css';
import MobileLevelCards from './mobile/MobileLevelCards';
import GOTFire from '../../../assets/home/levels/Fire.png';
import Portal from '../../../assets/home/levels/PistolPortal.png';
import Guntelet from '../../../assets/home/levels/Infinite.png';
import MetaCrystal from '../../../assets/home/levels/99percent.png';
import Bazinga from '../../../assets/home/levels/Bazinga.png';
import Carousel, { consts } from 'react-elastic-carousel';
import { HomeLevelsSVG1, HomeLevelsSVG2 } from '../../../utils/SVGs/home/Svgs';

const HomeLevels = () => {
  const items = [
    {
      number: '1',
      image: GOTFire,
      title: <Translation>{(t) => t('warmingUp')}</Translation>,
      paragraph: <Translation>{(t) => t('level1Text')}</Translation>,
      to: '/levels#level1',
    },
    {
      number: '2',
      image: Portal,
      title: <Translation>{(t) => t('letsGet')}</Translation>,
      paragraph: <Translation>{(t) => t('level2Text')}</Translation>,
      to: '/levels#level2',
    },
    {
      number: '3',
      image: Guntelet,
      title: <Translation>{(t) => t('halfwayThere')}</Translation>,
      paragraph: <Translation>{(t) => t('level3Text')}</Translation>,
      to: '/levels#level3',
    },
    {
      number: '4',
      image: MetaCrystal,
      title: <Translation>{(t) => t('almostDone')}</Translation>,
      paragraph: <Translation>{(t) => t('level4Text')}</Translation>,
      to: '/levels#level4',
    },
    {
      number: '5',
      image: Bazinga,
      title: <Translation>{(t) => t('chitChatting')}</Translation>,
      paragraph: <Translation>{(t) => t('level5Text')}</Translation>,
      to: '/levels#level5',
    },
  ];

  const levelArrow = ({ type, onClick, isEdge }) => {
    const pointer = type === consts.PREV ? '❰' : '❱';
    return (
      <button className='Home__Level-btn' onClick={onClick} disabled={isEdge}>
        {pointer}
      </button>
    );
  };

  return (
    <Translation>
      {(t) => (
        <div className='Home__levels-container'>
          <HomeLevelsSVG1 />
          <div className='Home__Levels-content'>
            <h1 className='Home__levels-h1'>{t('levels')}</h1>
            <p className='Home__levelsFeature'>{t('levelsFeature')}</p>
            <div className='Home__cards-container'>
              {items.map((item, i) => (
                <LevelCards key={i} {...item} />
              ))}
            </div>
            <div className='Home__mobileCards-container'>
              <Carousel
                renderArrow={levelArrow}
                renderPagination={({ pages, activePage, onClick }) => {
                  return (
                    <div>
                      {pages.map((page) => {
                        const isActivePage = activePage === page;
                        return (
                          <div
                            key={page}
                            onClick={() => onClick(page)}
                            active={isActivePage}
                          ></div>
                        );
                      })}
                    </div>
                  );
                }}
              >
                {items.map((item, i) => (
                  <MobileLevelCards key={i} {...item} />
                ))}
              </Carousel>
            </div>
          </div>
          <HomeLevelsSVG2 />
        </div>
      )}
    </Translation>
  );
};

export default HomeLevels;

import React from 'react';
import { Translation } from 'react-i18next';
import ReactPlayer from 'react-player';
import TvBase from '../../../assets/home/home-video/TvBase.svg';
// import PlayBtn from '../../../assets/home/home-video/PlayBtn.svg';
import TabletBtn from '../../../assets/home/home-video/TabletBtn.svg';
import CameraPhone from '../../../assets/home/home-video/PhoneCamera.svg';
import LockPhone from '../../../assets/home/home-video/PhoneLockBtn.svg';
import VolumePhone from '../../../assets/home/home-video/VolumePhoneBtn.svg';
import { HomeVideoSVG1, HomeVideoSVG2 } from '../../../utils/SVGs/home/Svgs'

import './HomeVideoBanner.css';
// import { configure } from '@testing-library/react';
import { homeVideoUrl } from "../../../config";

function HomeVideoBanner() {
  return (
    <Translation>
      {(t) => (
        <div className='Home__Video-Container'>
          <HomeVideoSVG1 />
          <h1 className='Home__Video-h1'>{t('homeVideoTitle')}</h1>
          <div className='Home__Video'>
            <section className='Home__Video-Player'>
              <div className='Home__video-TV'>
                <ReactPlayer
                  url={homeVideoUrl}
                  width='100%'
                  height='100%'
                // light={true}
                // playIcon={
                //   <img
                //     src={PlayBtn}
                //     className='Home__PlayBtn'
                //     alt='PlayBtn'
                //   />
                // }
                />
                <img
                  src={TabletBtn}
                  className='Home__Video-TabletBtn'
                  alt='TabletBtn'
                />
              </div>
              <img src={TvBase} alt='TvBase' className='Home__Video-TvBase' />
              <img
                src={LockPhone}
                alt='TvBase'
                className='Home__Video-LockBtn'
              />
              <img
                src={CameraPhone}
                alt='TvBase'
                className='Home__Video-CameraPhone'
              />
              <img
                src={VolumePhone}
                alt='TvBase'
                className='Home__Video-VolBtn'
              />
            </section>
            <section className='Home__Video-content'>
              <h1>{t('homeVideoSubtitle')}</h1>
              <p className='Home__Video-firstParagraph'>
                {t('homeVideoFirstParagraph')}
              </p>
              <p className='Home__Video-lastParagraph'>
                {t('homeVideoLastParagraph')}
              </p>
            </section>
          </div>
          <HomeVideoSVG2 />
        </div>
      )}
    </Translation>
  );
}

export default HomeVideoBanner;

import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Translation } from 'react-i18next';
import ReactPlayer from 'react-player';
import TvBase from '../../../assets/home/home-video/TvBase.svg';
import TabletBtn from '../../../assets/home/home-video/TabletBtn.svg';
import Sheldon from '../../../assets/academy/methodology/Sheldon.png';
import Leonard from '../../../assets/academy/methodology/Leonard.png';
// import CameraPhone from '../../../assets/home/home-video/PhoneCamera.svg';
// import LockPhone from '../../../assets/home/home-video/PhoneLockBtn.svg';
// import VolumePhone from '../../../assets/home/home-video/VolumePhoneBtn.svg';
import {
  MethodologySVG1,
  MethodologySVG2,
} from '../../../utils/SVGs/academy/Svgs';
import './Methodology.css';
import { homeVideoUrl } from "../../../config";

const Methodology = () => {

  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [location]);

  return (
    <Translation>
      {(t) => (
        <div className='Methodology__Container' id='feature_video'>
          <MethodologySVG1 />
          <h1 className='Methodology__h1'>{t('courseMethodology')}</h1>
          <div className='Methodology__Content'>
            <div className='Methodology__Player-container'>
              <ReactPlayer
                url={homeVideoUrl}
                width='100%'
                height='100%'
              />
              <img
                src={TabletBtn}
                alt='TabletBtn'
                className='Methodology__Tablet-btn'
              />
            </div>
            <img
              src={TvBase}
              alt='TvBase'
              className='Methodology__Video-TvBase'
            />
            {/* <img
              src={LockPhone}
              alt='TvBase'
              className='Methodology__LockBtn'
            />
            <img
              src={CameraPhone}
              alt='TvBase'
              className='Methodology__CameraPhone'
            />
            <img
              src={VolumePhone}
              alt='TvBase'
              className='Methodology__VolBtn'
            /> */}
          </div>
          <img src={Leonard} alt='leonard' className='Leonard' />
          <img src={Sheldon} alt='sheldon' className='Sheldon' />
          <MethodologySVG2 />
        </div>
      )}
    </Translation>
  );
};

export default Methodology;

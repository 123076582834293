import React from 'react';
import AlertModal from '../../../../utils/modals/AlertModals';
import ClosePink from '../../../../assets/general/alert/CloseBtnPink.svg';
import CloseBlue from '../../../../assets/general/alert/CloseBtnBlue.svg';
import IconPink from '../../../../assets/general/alert/WrongIcon.svg';
import IconBlue from '../../../../assets/general/alert/SendIcon.svg';
import Negashel from '../../../../assets/general/alert/Negashel.png';
import Posishel from '../../../../assets/general/alert/Posishel.png';
import { zpikDarkBlue, zpikPink } from '../../../../utils/color-palatte';

import './Alert.css';

const Alert = (props) => {
  return (
    <AlertModal
      isOpen={props.isOpen}
      overlayClassName='Overlay'
      modalClassName='Modal'
      Border={
        props.success ? `5px solid ${zpikDarkBlue}` : `5px solid ${zpikPink}`
      }
    >
      <img
        src={props.success ? CloseBlue : ClosePink}
        className='ModalCloseBtn'
        onClick={() => props.onRequestClose()}
        alt='Close'
      />
      <div className='AlerContainer'>
        <div className='AlertContent'>
          <img
            src={props.success ? IconBlue : IconPink}
            alt='icon'
            className='Alert__Icon'
          />
          <p className='AlertContent-text'>{props.content || 'something went wrong'}</p>
        </div>
        <div className='AlertImgContainer'>
          <img
            className='Shel'
            src={props.success ? Posishel : Negashel}
            alt='Shel'
          />
        </div>
      </div>
    </AlertModal>
  );
};

export default Alert;

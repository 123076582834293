import React from 'react';
import { Translation } from 'react-i18next';
import SafeURL from '../../../utils/safe-url';
import IGLogo from '../../../assets/footer/INSTAGRAMLOGO.svg';
import IGPink from '../../../assets/footer/INSTAGRAMLOGOPINK.svg';
// import FBLogo from '../../../assets/footer/FBLOGO.svg';
// import FBPink from '../../../assets/footer/FBLOGOPINK.svg';
// import TWTTRLogo from '../../../assets/footer/TWITTERLOGO.svg';
// import TWTTRPink from '../../../assets/footer/TWITTERLOGOPINK.svg';
import YTLogo from '../../../assets/footer/YOUTUBELOGO.svg';
import YTPink from '../../../assets/footer/YOUTUBELOGOPINK.svg';
import WSLogo from '../../../assets/footer/WHATSAPPLOGO.svg';
import WSPink from '../../../assets/footer/WHATSAPPLOGOPINK.svg';
import {
  companyInstagram,
  // companyFacebook,
  // companyTwitter,
  companyYouTube,
  contactNumber,
  InstagramUser,
  // FacebookUser,
  // TwitterUser,
  YouTubeUser,
  wsMe
} from '../../../config';
import Manthis from '../../../assets/contact/social-media/Manthis.png'

import './SocialContacts.css';
import {
  SocialContactSvgTop,
  SocialContactSvgBottom,
} from '../../../utils/SVGs/contact/Svgs';

const SocialList = ({ image, hover, text, link, type, target }) => {
  return (
    <SafeURL
      className='SocialContact__List-Item'
      dangerousURL={
        type === 'tel' || type === 'mailto' ? `${type}:${link}` : link
      }
      text={
        <div className='SocialContact__Li'>
          <img
            src={image}
            className='SocialContact__Li-image'
            alt='Social li'
          />
          <img
            src={hover}
            className='SocialContact__Li-hover'
            alt='Social li'
          />
          <p>{text}</p>
        </div>
      }
      newTab={target}
    />
  );
};

const SocialContacts = () => {
  const SocialNetWorks = [
    {
      image: IGLogo,
      hover: IGPink,
      text: InstagramUser,
      link: companyInstagram,
      target: true,
    },
    // {
    //   image: FBLogo,
    //   hover: FBPink,
    //   text: FacebookUser,
    //   link: companyFacebook,
    //   target: true,
    // },
    // {
    //   image: TWTTRLogo,
    //   hover: TWTTRPink,
    //   text: TwitterUser,
    //   link: companyTwitter,
    //   target: true,
    // },
    {
      image: YTLogo,
      hover: YTPink,
      text: YouTubeUser,
      link: companyYouTube,
      target: true,
    },
    {
      image: WSLogo,
      hover: WSPink,
      text: contactNumber,
      link: wsMe,
      target: true,
    },
  ];
  return (
    <Translation>
      {(t) => (
        <div className='SocialContact__Container'>
          <SocialContactSvgTop />
          <div className='SocialContact__Content'>
            <h1 className='SocialContact__h1'>{t('socialMediaContact')}</h1>
            <div className='SocialContent__Flex'>
              <div className='SocialContact__Networks-container'>
                {SocialNetWorks.map((networks) => (
                  <div style={{ marginTop: 20 }}>
                    <SocialList {...networks} />
                  </div>
                ))}
              </div>
              <div className='SocialContact__Img-Container'>
                <img alt='manthis' src={Manthis} className='Social__Manthis' />
              </div>
            </div>
          </div>
          <SocialContactSvgBottom />
        </div>
      )}
    </Translation>
  );
};

export default SocialContacts;

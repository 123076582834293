import React from 'react';
import TeacherCards from '../cards/TeachersCards';
import './TeacherMobile.css';

function TeacherMobile({
  TeacherPicture,
  BorderColor,
  TeacherName,
  TeacherProfession,
  Text,
}) {
  return (
    <div
      className='TeacherMobile__Container'
    >
      <img src={TeacherPicture} alt='TeacherBg' className='TeacherMobile__BG' />
      <TeacherCards
        TeacherPicture={TeacherPicture}
        BorderColor={BorderColor}
        TeacherName={TeacherName}
        TeacherProfession={TeacherProfession}
      />
      <p className='TeacherMobile__p'>{Text}</p>
    </div>
  );
}

export default TeacherMobile;

import React from 'react';
import './MobileLevelCards.css';
import ActionButton from '../../../../utils/action-button/ActionButton';
import { zpikDarkBlue } from '../../../../utils/color-palatte';
import { Translation } from 'react-i18next';

class MobileLevelCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstMatches: window.matchMedia('(max-width: 530px)').matches,
      secondMatches: window.matchMedia('(max-width: 400px)').matches,
    };
  }

  componentDidMount = () => {
    const firstHandler = (e) => this.setState({ firstMatches: e.matches });
    window.matchMedia('(max-width: 530px)').addListener(firstHandler);

    const secondHandler = (e) => this.setState({ secondMatches: e.matches });
    window.matchMedia('(max-width: 400px)').addListener(secondHandler);
  };

  render() {
    const { number, image, title, paragraph, to } = this.props;
    return (
      <Translation>
        {(t) => (
          <div
            className='Mobile__Cards-container'
            style={
              number === '' || number === undefined
                ? { display: 'none' }
                : { display: 'flex' }
            }
          >
            <div className='Mobile__Cards-firstDiv'>
              <div className='Mobile__Cards-content'>
                <div className='Cards__firsDiv-mobile'>
                  <h1>#{number}</h1>
                  <div className='Cards__img-container-mobile'>
                    <img src={image} alt='card-img' />
                  </div>
                </div>
                <h2 className='Mobile__Cards-title'>{title}</h2>
                <div className='Mobile__Cards-Paragraph'>{paragraph}</div>
                <ActionButton
                  ButtonClass='Link'
                  Width='unset'
                  Height='unset'
                  SafeLink={to}
                  TextContent={t('moreInfo')}
                  Padding='10px 20px'
                  Color='white'
                  Transform='scale(1.1, 0.9)'
                  FontWeight='900'
                  FontSize={
                    this.state.firstMatches
                      ? this.state.secondMatches
                        ? '.7em'
                        : '.8em'
                      : '1.3em'
                  }
                  BGColor={zpikDarkBlue}
                  BorderRadius='10px'
                  TextTransform='uppercase'
                  TextAlign='center'
                />
                {/* <SafeLINK
                  className='Mobile__Cards-button'
                  text={t('moreInfo')}
                  dangerousLINK={to}
                /> */}
              </div>
              <div className='Mobile__Cards-imgContainer'>
                <img src={image} alt='MobileImg' />
              </div>
              <img
                src={image}
                alt='MobileImg'
                className='Mobile__Card-BackImg'
              />
            </div>
          </div>
        )}
      </Translation>
    );
  }
}

export default MobileLevelCards;

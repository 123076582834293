import React from 'react';
import Footer from './footer/Footer';
import Header from './header/Header'
import PartnerBanner from './general/partners/PartnerBanner';

const Layout = ({children}) => (
  <>
    <Header />
    {children}
    <Footer />
    {/* <PartnerBanner/> */}
  </>
)

export default Layout;
import React from 'react';
import { Translation } from 'react-i18next';
import './Contact.css';
import FindUs from '../../components/contact/find-us/FindUs';
import ContactUs from '../../components/general/contact-us/ContactUs';
import SocialContacts from '../../components/contact/social-contact/SocialContacts';
import Suggestions from '../../components/contact/suggestions/Suggestions';
function Contact() {
  return (
    <div className='Contact'>
      <FindUs />
      <ContactUs
        title={<Translation>{(t) => t('writeUs')}</Translation>}
        classColor='pink'
        alignButton='center'
        RenderSVGBottom={true}
        RenderSVGTop={true}
      />
      <SocialContacts />
      <Suggestions />
    </div>
  );
}

export default Contact;

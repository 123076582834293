import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import './LevelMap.css';

const LevelMap = ({
  Svg1,
  FirstTitle,
  LevelNumber,
  LevelTitle,
  LevelImage,
  Paragraph1,
  Paragraph2,
  StrongTitle,
  StrongText,
  LastParagraph,
  CharacterImage,
  Svg2,
  theHash,
}) => {
  const location = useLocation();
  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [location]);
  return (
    <div className='Level__Container' id={theHash}>
      {Svg1}
      <div className='Level__Content'>
        {FirstTitle && <h2 className='level__Title'>{FirstTitle}</h2>}
        <div className='Level__Header'>
          <h1 className='Level__h1'>
            #{LevelNumber}
            <span> {LevelTitle}</span>
          </h1>
          <img
            src={LevelImage}
            alt='LevelImage'
            className='Level__Header-image'
          />
        </div>
        <h3 className='Level__h3'>{LevelTitle}</h3>
        {Paragraph1 && <p className='Level__Paragraph'>{Paragraph1}</p>}
        {Paragraph2 && <p className='Level__Paragraph'>{Paragraph2}</p>}
        {StrongText && (
          <p className='Level__Strong'>
            {StrongTitle && <span>{StrongTitle}: </span>}
            {StrongText}
          </p>
        )}
        {LastParagraph && <p className='Level__Paragraph'>{LastParagraph}</p>}
      </div>
      {Svg2}
      <img
        src={CharacterImage}
        alt='CharacterImage'
        className='Level__Character'
      />
      <img src={LevelImage} alt='BG' className='Level__BG' />
    </div>
  );
};

export default LevelMap;

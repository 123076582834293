import CryptoJS from "crypto-js";
import { cryptoJsSecretKey } from "../config";

const decrypt = (object) => {
    return new Promise((resolve, reject) => {
        var bytes = CryptoJS.AES.decrypt(object, cryptoJsSecretKey);
        const response = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        resolve(response);
    })
}

const encrypt = (data) => {
    // console.log("encrypt / data: ", data);
    var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), cryptoJsSecretKey).toString();
    // console.log("encrypt / ciphertext: ", ciphertext);
    return ciphertext;
}

export {
    encrypt,
    decrypt
}
import React from 'react';
import { Translation } from 'react-i18next';
import YellowDot from '../../../assets/contact/find-us/YellowDot.svg';
import { FindUsSvgBottom } from '../../../utils/SVGs/contact/Svgs';
import SafeURL from '../../../utils/safe-url';
import { companyDirection, companyLocation } from '../../../config';
import {
  MapContainer,
  TileLayer,
  //Popup, Marker
} from 'react-leaflet';
import Ship from '../../../assets/contact/find-us/Ship.png';
import Shadow from '../../../assets/contact/find-us/shadow.png';
import './FindUs.css';

const FindUs = () => {
  const position = [10.579, -71.63];
  return (
    <Translation>
      {(t) => (
        <div className='FindUs__Container'>
          <h1 className='FindUs__Title'>
            {t('findUs')}
            <br />
            {t('rightHere')}
          </h1>
          <div className='FindUs__Map-Container'>
            <MapContainer
              style={{ width: '100%', height: '740px' }}
              center={position}
              zoom={10}
              scrollWheelZoom={false}
            >
              <TileLayer
                attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
              />
              {/* <Marker position={position}>
                <Popup>Aqui estamos</Popup>
              </Marker> */}
            </MapContainer>
          </div>
          <section className='FindUs__Direction'>
            <img src={YellowDot} className='FindUs__MapLogo' alt='MapLogo' />
            <SafeURL
              text={companyDirection}
              dangerousURL={companyLocation}
              className='FindUs__a'
              newTab={true}
            />
          </section>
          <img src={Shadow} alt='Shadow' className='FindUs__Shadow' />
          <img src={Ship} alt='Ship' className='FindUs__Ship' />
          <FindUsSvgBottom />
        </div>
      )}
    </Translation>
  );
};

export default FindUs;

import React from 'react';
import { Translation } from 'react-i18next';
import Logo from '../../assets/header/LOGO.svg';
import './Footer.css';
import SafeURL from '../../utils/safe-url';
import SafeLINK from '../../utils/safe-link';
// import Twitter from '../../assets/footer/TWITTERLOGO.svg';
// import TwitterPink from '../../assets/footer/TWITTERLOGOPINK.svg';
import WhatsApp from '../../assets/footer/WHATSAPPLOGO.svg';
import WhatsAppPink from '../../assets/footer/WHATSAPPLOGOPINK.svg';
// import Facebook from '../../assets/footer/FBLOGO.svg';
// import FacebookPink from '../../assets/footer/FBLOGOPINK.svg';
import Instagram from '../../assets/footer/INSTAGRAMLOGO.svg';
import InstagramPink from '../../assets/footer/INSTAGRAMLOGOPINK.svg';
import Youtube from '../../assets/footer/YOUTUBELOGO.svg';
import YoutubePink from '../../assets/footer/YOUTUBELOGOPINK.svg';
import {
  companyInstagram,
  // companyFacebook,
  // companyTwitter,
  companyYouTube,
  contactNumber,
  companyEmail,
  // InstagramUser,
  // FacebookUser,
  // TwitterUser,
  YouTubeUser,
  wsMe
} from '../../config';

function Footer() {
  return (
    <Translation>
      {(t) => (
        <section className='Footer__Container'>
          <section className='Footer__Container-block'>
            <section className='Footer__Menu'>
              <SafeLINK
                dangerousLINK='/'
                text={
                  <img src={Logo} alt='logo' className='Footer__Logo' />
                }
              />
              {/* <a href="">Lorem Ipsum</a>
              <a href="">Lorem Ipsum dolor</a> */}
              <SafeURL
                dangerousURL={`tel:${contactNumber}`}
                text={contactNumber}
                style={{cursor: 'pointer'}}
              />
              {/* <a href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${companyEmail}`}>{`Email: ${companyEmail}`}</a> */}
              <SafeURL
                dangerousURL={`mailto:${companyEmail}`}
                text={companyEmail}
                style={{cursor: 'pointer'}}
              />
            </section>
            <section className='Footer__Menu'>
              <h1>Menu</h1>
              <SafeLINK
                className='Footer__Nav'
                dangerousLINK='/home'
                text={t('home')}
              />
              <SafeLINK
                className='Footer__Nav'
                dangerousLINK='/academy'
                text={t('academy')}
              />
              <SafeLINK
                className='Footer__Nav'
                dangerousLINK='/levels'
                text={t('levels')}
              />
              <SafeLINK
                className='Footer__Nav'
                dangerousLINK='/contact'
                text={t('contact')}
              />
            </section>
            <section className='Footer__Menu'>
              <h1>Social Media</h1>
              {/* <SafeURL
                dangerousURL=''
                newTab={true}
                text={
                  <div className='Footer__Network'>
                    <div className='Footer__Network-logo'>
                      <img src={Twitter} alt='TWTTR' />
                      <img
                        src={TwitterPink}
                        className='Footer__Network-logoChange'
                        alt='TWTTR'
                      />
                    </div>
                    <p>Twitter</p>
                  </div>
                }
              /> */}
              <SafeURL
                newTab={true}
                dangerousURL={wsMe}
                text={
                  <div className='Footer__Network'>
                    <div className='Footer__Network-logo'>
                      <img src={WhatsApp} alt='WHSPP' />
                      <img
                        src={WhatsAppPink}
                        className='Footer__Network-logoChange'
                        alt='WHSPP'
                      />
                    </div>
                    <p>WhatsApp</p>
                  </div>
                }
              />
              {/* <SafeURL
                newTab={true}
                text={
                  <div className='Footer__Network'>
                    <div className='Footer__Network-logo'>
                      <img src={Facebook} alt='FBOOK' />
                      <img
                        src={FacebookPink}
                        className='Footer__Network-logoChange'
                        alt='FBOOK'
                      />
                    </div>
                    <p>Facebook</p>
                  </div>
                }
              /> */}
              <SafeURL
                dangerousURL={companyInstagram}
                newTab={true}
                text={
                  <div className='Footer__Network'>
                    <div className='Footer__Network-logo'>
                      <img src={Instagram} alt='INSTGR' />
                      <img
                        src={InstagramPink}
                        className='Footer__Network-logoChange'
                        alt='INSTGR'
                      />
                    </div>
                    <p>Instagram</p>
                  </div>
                }
              />
              <SafeURL
                dangerousURL={companyYouTube}
                newTab={true}
                text={
                  <div className='Footer__Network'>
                    <div className='Footer__Network-logo'>
                      <img src={Youtube} alt='YUTBE' />
                      <img
                        src={YoutubePink}
                        className='Footer__Network-logoChange'
                        alt='YUTBE'
                      />
                    </div>
                    <p>{YouTubeUser}</p>
                  </div>
                }
              />
            </section>
          </section>
          <p className='Footer__Copy'>
            &copy;{t('copy')}
          </p>
        </section>
      )}
    </Translation>
  );
}

export default Footer;
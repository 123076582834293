// API's
// export const API_URL = 'http://192.168.1.107:4000'
export const API_URL = process.env.REACT_APP_API_URL;
export const timeToResponse = process.env.REACT_APP_TIME_TO_RESPONSE; //time to response from server before show timeout error
export const cryptoJsSecretKey = process.env.REACT_APP_CRYPTO_JS_SECRET_KEY

// Company Info
export const contactNumber = process.env.REACT_APP_CONTACT_NUMBER
export const companyEmail = process.env.REACT_APP_COMPANY_EMAIL
export const companyDirection = process.env.REACT_APP_COMPANY_DIRECTION
export const companyInstagram = process.env.REACT_APP_COMPANY_INSTAGRAM
export const InstagramUser = process.env.REACT_APP_INSTAGRAM_USER
export const companyFacebook = process.env.REACT_APP_COMPANY_FACEBOOK
export const FacebookUser = process.env.REACT_APP_FACEBOOK_USER
export const companyTwitter = process.env.REACT_APP_COMPANY_TWITTER
export const TwitterUser = process.env.REACT_APP_TWITTER_USER
export const companyYouTube = process.env.REACT_APP_COMPANY_YOUTUBE
export const YouTubeUser = process.env.REACT_APP_YOUTUBE_USER
export const homeVideoUrl = process.env.REACT_APP_HOME_VIDEO_URL
export const companyLocation = process.env.REACT_APP_COMPANY_LOCATION
export const tgMe = process.env.REACT_APP_TG_ME; 
export const wsMe = process.env.REACT_APP_WS_ME;

// export const levelingMsgEs = 'https://wa.me/584126418098?text=Quiero%20hacer%20el%20test%20de%20nivelacion'
export const levelingMsgEs = process.env.REACT_APP_LEVELIN_MSG_ES
export const joinMsgEs = process.env.REACT_APP_JOIN_ES

// export const levelingMsgEn = 'https://wa.me/584126418098?text=I%20want%20to%20do%20the%20leveling%20test'
export const levelingMsgEn = process.env.REACT_APP_LEVELIN_MSG_EN
export const joinMsgEn = process.env.REACT_APP_JOIN_EN

import React from 'react';
import './Svgs.css';

// Academy About

export const AboutContainerSVG = () => (
  <svg
    className='About__ContainerSVG'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 60 A 60 120 0 0 0 101 -1 L 101 101 L -1 101z' />
  </svg>
);

export const AboutContainerSVG2 = () => (
  <svg
    className='About__ContainerSVG2'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 10 A 60 90 0 0 0 101 -1 L 101 101 L -1 101z' />
  </svg>
);

export const AboutContainerSVG2a = () => (
  <svg
    className='About__ContainerSVG2a'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M 0 0 L 100 0 L 100 100 L 0 100z' />
  </svg>
);

// Academy Methodology

export const MethodologySVG1 = () => (
  <svg
    className='Methodology__ContainerSVG1'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 101 A 54 120 0 0 1 101 80 L 101 -1 L -1 -1z' />
  </svg>
);

export const MethodologySVG2 = () => (
  <svg
    className='Methodology__ContainerSVG2'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 -1 A 40 75 0 1 0 101 -1 L 101 101 L -1 101z' />
  </svg>
);

// Academy Teachers

export const TeachersSVG1 = () => (
  <svg
    className='Teachers-SVG1'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 70 A 51.5 75 0 0 1 101 75 L 101 -1 L -1 -1z' />
  </svg>
);

export const TeachersSVG2 = () => (
  <svg
    className='Teachers-SVG2'
    viewBox='0 0 100 100'
    preserveAspectRatio='none'
  >
    <path d='M -1 60 A 58 75 0 0 0 101 55 L 101 101 L -1 101z' />
  </svg>
);

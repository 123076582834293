import React from 'react';
import ReactDOM from 'react-dom';
import './AlertModals.css';

const AlertModal = (props) => {
  if (!props.isOpen) {
    return null;
  }
  return ReactDOM.createPortal(
    <div className='Modal'>
      <div className={`overlay ${props.overlayClassName}`}>
        <div className={`Modal__container ${props.modalClassName}`}
        style={{ border: props.Border }}>{props.children}</div>
      </div>
    </div>,
    document.getElementById('modals')
  );
};

export default AlertModal;
